import { useNavigate } from "react-router";
import styles from "./NotFoundTemplate.module.css";

export default function NotFoundTemplate({admin}){

    const navigate = useNavigate();

    return (
      <div className={styles.container}>
        <div className={styles.heading}>
            <h1>404</h1>
            <h3>Página no encontrada</h3>
        </div>
        <p>La página que estás buscando no existe.<br/>Probá de nuevo volviendo al inicio.</p>
        <p 
            className={styles.button}
            onClick={() => navigate(admin ? "/admin/app/users" : "/app/dashboard")}
        >
            Volver al inicio
        </p>
      </div>
    );
}
  