import { useNavigate } from 'react-router';
import Button from '../../../atoms/Button/Button';
import styles from './OnboardingTemplate.module.css';
import ContactButton from '../../../atoms/ContactButton/ContactButton';

export default function OnboardingTemplate(props){

  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <Button 
          text="Iniciar sesión" 
          style="primary" 
          size="md"
          handleClick={() => navigate('/sign-in')}
          fullWidth
        />
      </div>
      <ContactButton/>
    </div>
  );
}
  
  