// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MovementsTemplate_container__qbduV{
    padding: 60px;
    position: relative;
    height: 100%;
    overflow-y: scroll;
}
.MovementsTemplate_contentContainer__GQSHb{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
}
.MovementsTemplate_heading__f7sDr{
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 500;
}
.MovementsTemplate_heading__f7sDr h1{
    font-size: 52px;
    font-weight: 600;
    color: var(--primary-dark-color);
}

@media (max-width: 576px) {
    .MovementsTemplate_container__qbduV{
        padding: 30px 20px 45px 20px;
    }
    .MovementsTemplate_heading__f7sDr{
        flex-direction: column;
    }
    .MovementsTemplate_heading__f7sDr h1{
        font-size: 32px;
    }
    .MovementsTemplate_heading__f7sDr button{
        padding: 16px 24px !important;
        margin-top: 12px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/templates/users/MovementsTemplate/MovementsTemplate.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,WAAW;AACf;AACA;IACI,mBAAmB;IACnB,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,gCAAgC;AACpC;;AAEA;IACI;QACI,4BAA4B;IAChC;IACA;QACI,sBAAsB;IAC1B;IACA;QACI,eAAe;IACnB;IACA;QACI,6BAA6B;QAC7B,gBAAgB;IACpB;AACJ","sourcesContent":[".container{\n    padding: 60px;\n    position: relative;\n    height: 100%;\n    overflow-y: scroll;\n}\n.contentContainer{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n    width: 100%;\n}\n.heading{\n    margin-bottom: 32px;\n    display: flex;\n    justify-content: space-between;\n    position: relative;\n    z-index: 500;\n}\n.heading h1{\n    font-size: 52px;\n    font-weight: 600;\n    color: var(--primary-dark-color);\n}\n\n@media (max-width: 576px) {\n    .container{\n        padding: 30px 20px 45px 20px;\n    }\n    .heading{\n        flex-direction: column;\n    }\n    .heading h1{\n        font-size: 32px;\n    }\n    .heading button{\n        padding: 16px 24px !important;\n        margin-top: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MovementsTemplate_container__qbduV`,
	"contentContainer": `MovementsTemplate_contentContainer__GQSHb`,
	"heading": `MovementsTemplate_heading__f7sDr`
};
export default ___CSS_LOADER_EXPORT___;
