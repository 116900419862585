// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OnboardingTemplate_container__eARTx{
    width: 100%;
    animation-name: OnboardingTemplate_contentAppearance__BU0MJ;
    animation-delay: 0.8s;
    animation-duration: 0.4s;
    animation-timing-function: ease-in;
    opacity: 0;
    animation-fill-mode: forwards;
}
.OnboardingTemplate_buttonContainer__YnH59{
    padding: 12px 0px;
    margin-bottom: 32px;
}



@keyframes OnboardingTemplate_contentAppearance__BU0MJ {
    0%{
        opacity:0;
    }
    100%{
        opacity: 1;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/templates/auth/OnboardingTemplate/OnboardingTemplate.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,2DAAiC;IACjC,qBAAqB;IACrB,wBAAwB;IACxB,kCAAkC;IAClC,UAAU;IACV,6BAA6B;AACjC;AACA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;;;AAIA;IACI;QACI,SAAS;IACb;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".container{\n    width: 100%;\n    animation-name: contentAppearance;\n    animation-delay: 0.8s;\n    animation-duration: 0.4s;\n    animation-timing-function: ease-in;\n    opacity: 0;\n    animation-fill-mode: forwards;\n}\n.buttonContainer{\n    padding: 12px 0px;\n    margin-bottom: 32px;\n}\n\n\n\n@keyframes contentAppearance {\n    0%{\n        opacity:0;\n    }\n    100%{\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `OnboardingTemplate_container__eARTx`,
	"contentAppearance": `OnboardingTemplate_contentAppearance__BU0MJ`,
	"buttonContainer": `OnboardingTemplate_buttonContainer__YnH59`
};
export default ___CSS_LOADER_EXPORT___;
