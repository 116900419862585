// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignInTemplate_container__d8Q\\+1{
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/templates/auth/SignInTemplate/SignInTemplate.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf","sourcesContent":[".container{\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SignInTemplate_container__d8Q+1`
};
export default ___CSS_LOADER_EXPORT___;
