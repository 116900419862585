// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tabs_tabsContainer__IJJea{
    border-bottom: 1px solid rgba(128, 128, 128, 0.253);
    margin-top: 15px;
}
.Tabs_tabContainer__uIz6h{
    padding-top: 20px;
}

.Tabs_buttonContainer__bssBF{
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    position:relative;
    margin-bottom: 52px;
}
.Tabs_dateContainer__bpUzI{
    position: absolute;
    margin:0;
    bottom: -32px;
    right: 0;
    color: grey;
    font-style: italic;
    font-size: 14px;
}

@media (max-width:576px) {
    .Tabs_buttonContainer__bssBF button{
        width:100%;
    }
    .Tabs_dateContainer__bpUzI{
        right: unset;
        left: 30px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/Tabs/Tabs.module.css"],"names":[],"mappings":"AAAA;IACI,mDAAmD;IACnD,gBAAgB;AACpB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,qBAAqB;IACrB,sBAAsB;IACtB,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,aAAa;IACb,QAAQ;IACR,WAAW;IACX,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,YAAY;QACZ,UAAU;IACd;AACJ","sourcesContent":[".tabsContainer{\n    border-bottom: 1px solid rgba(128, 128, 128, 0.253);\n    margin-top: 15px;\n}\n.tabContainer{\n    padding-top: 20px;\n}\n\n.buttonContainer{\n    width: 100%;\n    display: flex;\n    align-items: flex-end;\n    flex-direction: column;\n    position:relative;\n    margin-bottom: 52px;\n}\n.dateContainer{\n    position: absolute;\n    margin:0;\n    bottom: -32px;\n    right: 0;\n    color: grey;\n    font-style: italic;\n    font-size: 14px;\n}\n\n@media (max-width:576px) {\n    .buttonContainer button{\n        width:100%;\n    }\n    .dateContainer{\n        right: unset;\n        left: 30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsContainer": `Tabs_tabsContainer__IJJea`,
	"tabContainer": `Tabs_tabContainer__uIz6h`,
	"buttonContainer": `Tabs_buttonContainer__bssBF`,
	"dateContainer": `Tabs_dateContainer__bpUzI`
};
export default ___CSS_LOADER_EXPORT___;
