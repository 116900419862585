import { useContext } from "react";
import AppTemplate from "../../../components/templates/AppTemplate/AppTemplate";
import UserFormTemplate from "../../../components/templates/admin/UserFormTemplate/UserFormTemplate";
import { adminAppRoutes } from "../../../data/routes";
import { AppContext } from "../../../contexts/AppContext";
import { addUserForm } from "../../../data/forms";


export default function AddUser(){

  const {addUser, addUserData, setAddUserData} = useContext(AppContext);

  return (
    <main>
      <AppTemplate routes={adminAppRoutes}>
        <UserFormTemplate 
          title="Agregar usuario"
          formData={addUserForm}
          action={addUser}
          apiData={addUserData}
          setApiData={setAddUserData}
        />
      </AppTemplate>
    </main>
  );
}
  