import { Link } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import FormGroup from '../../molecules/FormGroup/FormGroup';
import styles from './Form.module.css';
import { useState } from 'react';

export default function Form(props){

  const {formFields, button, signin, signup, sendCode, setApiData, apiData, buttonId, formId} = props;

  const handleResendCode = () => {
    setInterval(() => {
      
    })
  }
  const [resendButton, setResendButton] = useState({
    disabled: false, 
    text:"¿No recibiste el código? Reintentar"
  });


  return (
    <form className={styles.container} id={formId}>
      <div className={styles.formContainer}>
        {formFields?.map((field, i) => (
            <FormGroup 
              key={i} 
              {...field} 
              setApiData={setApiData} 
              apiData={apiData}
            />
        ))}
      </div>
      {signin && 
          <div className={styles.linkContainer}>
              <Link to="/recover-password/email">
                  ¿Olvidaste tu contraseña?
              </Link>
          </div>
      }
      {signup && 
          <div className={styles.linkContainer}>
              <Link to="/sign-in">
                  ¿Ya estás registrado? Iniciá sesión
              </Link>
          </div>
      }
      {sendCode && 
          <div className={styles.linkContainer}>
              <p onClick={resendButton.disabled ? undefined : () => handleResendCode()}>
                  {resendButton.text}
              </p>
          </div>
      }
      <Button {...button} id={buttonId}/>
    </form>
  );
  }
  
  