import styles from './Button.module.css';

export default function Button(props){

    const {text, style, accept, handleClick, handleChange, fullWidth, size, type, icon, id, disabled = false } = props;

    return (
      type == "file" ?
        <>
          <label 
            htmlFor="file-upload" 
            className={[
              styles.button, 
              styles[style], 
              styles[fullWidth && "fullWidth"],
              styles[size]
            ].join(' ')}
          >
              {text}
          </label>
          <input 
            id="file-upload" 
            className={styles.fileButton} 
            type="file"
            accept={accept}
            onChange={(e) => handleChange(e)}
          />
        </> :
        <button 
          className={[
            styles.button, 
            styles[style], 
            styles[fullWidth && "fullWidth"],
            styles[size],
            disabled && styles['disabled']
          ].join(' ')}
          onClick={handleClick}
          type="button"
          id={id}
          disabled={disabled}
        >
          {icon && <img src={icon} alt="icon"/>}
          {text}
        </button>
    );
}
  