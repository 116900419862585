import { useEffect } from 'react';
import styles from './Toast.module.css';

export default function Toast(props){

    const {toast, setToast, content} = props;

    useEffect(() => {
        toast.show &&
        setTimeout(() => {
            setToast({show:false, content:""});
        }, 3000)
    }, [content])

    return (
        toast.show &&
        <div className={styles.container}>
            {Array.isArray(content) ?
                content?.map((error,i) => (
                    <p 
                        key={i}
                        className={styles.content}
                    >
                        -{error}
                    </p>
                )) : 
                <p 
                    className={styles.content}
                >
                    -{content}
                </p>
            }
        </div>
    );
}
  