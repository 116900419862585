import styles from './ProfileTemplate.module.css';
import {profileChangePassForm, profileDataForm} from '../../../../data/forms';
import Form from '../../../organisms/Form/Form';
import { useNavigate } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import FormGroup from '../../../molecules/FormGroup/FormGroup';

export default function ProfileTemplate(props){

  const {changePassword, editProfile, setChangePassData, changePassData, setEditProfileData, editProfileData} = props;
  const {changePasswordStatus, setChangePasswordStatus, changeSettingsStatus,setMaintenancePage} = useContext(AppContext);

  const [isMaintenance, setIsMaintenance] = useState({value:false});

  useEffect(() => {
    const defaultMaintenanceValue = JSON.parse(localStorage.getItem('isMaintenanceActive'));
    setIsMaintenance({value:defaultMaintenanceValue ?? false});
  },[])

  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h1>Configuración</h1>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <div>
            <h2 className={styles.title}>{profileDataForm.title}</h2>
            <Form 
              formFields={profileDataForm.formFields}
              button={{
                ...profileDataForm.button,
                handleClick: () => editProfile(editProfileData, navigate)
              }}
              setApiData={setEditProfileData}
              apiData={editProfileData}
            />
          </div>
        <div>
          <h2 className={styles.title}>{profileChangePassForm.title}</h2>
          <Form 
            formFields={profileChangePassForm.formFields}
            button={{
              ...profileChangePassForm.button,
              text:
                changePasswordStatus == 'success' ?
                "Contraseña modificada con éxito!" :
                changePasswordStatus == 'pending' ?
                "Modificando contraseña..." :
                "Modificar contraseña"
              ,
              style:
                changePasswordStatus == 'success' ?
                "success" :
                changePasswordStatus == 'pending' ?
                "disabled" :
                "primary"
              ,
              handleClick: () => 
              (changePasswordStatus == 'pending'|| changePasswordStatus == 'success') ? 
              undefined : 
              changePassword(changePassData, navigate)
            }}
            setApiData={setChangePassData}
            apiData={changePassData}
          />
        </div>
        </div>
        <div className={styles.content}>
          <h2 className={styles.title}>Configuración del sitio</h2>
          <Form
            formFields={[{
              label:"Activar página de mantenimiento",
              tag:"switch",
              id:"value"
            }]}
            setApiData={setIsMaintenance}
            apiData={isMaintenance}
            button={{
              size:'sm',
              fullWidth:true,
              text:
                changeSettingsStatus == 'success' ?
                "Cambios guardados con éxito!" :
                changeSettingsStatus == 'pending' ?
                "Guardando cambios..." :
                "Guardar cambios"
              ,
              style:
                changeSettingsStatus == 'success' ?
                "success" :
                changeSettingsStatus == 'pending' ?
                "disabled" :
                "primary"
              ,
              handleClick: () => 
              (changeSettingsStatus == 'pending'|| changeSettingsStatus == 'success') ? 
              undefined : 
              setMaintenancePage(isMaintenance, navigate)
            }}
          />
        </div>
      </div>
    </div>
  );
}
  
  