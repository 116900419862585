import styles from './CardDocument.module.css';
import users from '../../../assets/icons/reports.svg';
import download from '../../../assets/icons/download.svg';
import Button from '../../atoms/Button/Button';

export default function CardDocument(props){

    const {url, label} = props;

    return (
      <div className={styles.card}>
        <div className={styles.content}>
          <div className={styles.item}>
            <div className={styles.icon}>
              <img src={users} alt="icon"/>
            </div>
          </div>
          <div className={styles.item}>
            <h3 className={styles.value}>
              {label}
            </h3>
          </div>
        </div>
        <a className={styles.downloadButton} href={url} download={label} target="_blank">
          <img className={styles.download} src={download}/>
          Descargar
        </a>
      </div>
    );
  }
  
  