import capital from '../assets/bk/capital.svg';
import existencias from '../assets/bk/existencias.svg';
import rendimiento from '../assets/bk/rendimiento.svg';

export const dashboardTestData = {
    metricsCards: [
        {
            bkIcon: capital,
            title: "Capital",
            subtitle: "250.000",
            color:"primary"
        },
        {
            bkIcon: existencias,
            title: "Existencia",
            subtitle: "254.654",
            color:"secondary"
        },
        {
            bkIcon: rendimiento,
            title: "Rendimiento",
            subtitle: "4.654",
            percentage:"2,5",
            color:"tertiary"
        },
    ],
    lastMovement:{
        existing:"255.550",
        capital:"250.000",
        currencyPerformance:"5.550",
        performancePercentage:"4,53",
        dateStart:"12/01/2024",
        dateEnd:"17/08/2024",
        dateRenew:"20/08/2024",
        method: "Cripto"
    },
    graphic:{
        labels: [
            'Life Settlement', 
            "Cripto", 
            "Cash"
        ],
        datasets:[
            {
                data: [250000, 60000, 10000],
                backgroundColor: [
                    '#2850EA',
                    "#6994f1e8",
                    '#bccbef'
                ],
                borderWidth:0,
            }
        ],
    }
}

export const usersTestData = [
    {
        firstName:"Joe",
        lastName:"Doe",
        email: "joedoe@example.com",
        phone: "+549113100000000"
    },
    {
        firstName:"Joe",
        lastName:"Doe",
        email: "joedoe@example.com",
        phone: "+549113100000000"
    },
    {
        firstName:"Joe",
        lastName:"Doe",
        email: "joedoe@example.com",
        phone: "+549113100000000"
    },
    {
        firstName:"Joe",
        lastName:"Doe",
        email: "joedoe@example.com",
        phone: "+549113100000000"
    },
    {
        firstName:"Joe",
        lastName:"Doe",
        email: "joedoe@example.com",
        phone: "+549113100000000"
    },
    {
        firstName:"Joe",
        lastName:"Doe",
        email: "joedoe@example.com",
        phone: "+549113100000000"
    },
    {
        firstName:"Joe",
        lastName:"Doe",
        email: "joedoe@example.com",
        phone: "+549113100000000"
    },
]
