import { Offcanvas } from 'react-bootstrap';
import styles from './Filter.module.css';
import FormGroup from '../../molecules/FormGroup/FormGroup';
import Button from '../../atoms/Button/Button';
import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import Form from '../Form/Form';


export default function Filter(props){

    const {show, setShow, filters, setFilters, getApiData, formFields, button} = props;

    const handleClick = () => {
        setShow(false);
        getApiData(filters);
    }

    const handleDeleteFilters = () => {
        setShow(false);
        setFilters({})
        getApiData();
    }

    return (
        <Offcanvas 
            show={show} 
            onHide={() => setShow(false)} 
            placement="end"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Filtros</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className={styles.body}>
                <Form
                    formFields={formFields}
                    apiData={filters}
                    setApiData={setFilters}
                    button={{
                        ...button, 
                        handleClick:() => handleClick(),
                    }}
                />
                <Button
                    text="Borrar filtros"
                    style="white"
                    fullWidth
                    size="sm"
                    handleClick={() => handleDeleteFilters()}
                />
            </Offcanvas.Body>
        </Offcanvas>
    );
}
  