import styles from './ModalOptions.module.css';
import close from '../../../assets/icons/close.svg'
import OptionButton from '../../atoms/OptionButton/OptionButton';
import { useState } from 'react';

export default function ModalOptions({show, setShow, showContent, setShowContent, options, children}){

  return (
    show &&
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <img 
          src={close} 
          className={styles.close} 
          alt="icon"
          onClick={() => {
            setShow(false);
            setShowContent({
              options:true, content:false, status:false, option: 'all'
            })
          }}
        />
        <h3>¿Qué acción querés realizar?</h3>
        {showContent?.options && 
          <>
            <div className={styles.buttonContainer}>
              {options?.map((option, i) => (
                <OptionButton
                  key={i}
                  {...option}
                  style={(showContent?.option === option?.id) && 'active'}
                  onClick={
                    option?.onClick || 
                    (() => setShowContent({
                      ...showContent, 
                      content: true,
                      option: option?.id
                    }))
                  }
                />
              ))}
            </div>
            <div className={styles.contentContainer}>
              {children}
            </div>
          </>    
        }
      </div>
    </div>
  );
  }
  
  