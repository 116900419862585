import { useContext, useEffect } from "react";
import Loader from "../../components/atoms/Loader/Loader";
import AppTemplate from "../../components/templates/AppTemplate/AppTemplate";
import ProfileTemplate from "../../components/templates/users/ProfileTemplate/ProfileTemplate";
import { usersAppRoutes } from "../../data/routes";
import { AppContext } from "../../contexts/AppContext";
import { AuthContext } from "../../contexts/AuthContext";


export default function Profile(){

  const {editProfile, editProfileData, setEditProfileData, changePassword, setChangePassData, changePassData, loading, getUser} = useContext(AppContext);
  const {userInfo} = useContext(AuthContext);


  return (
    <main>
      {loading ? 
      <Loader/> :
      <AppTemplate routes={usersAppRoutes}>
        <ProfileTemplate
          editProfile={editProfile}
          editProfileData={editProfileData}
          setEditProfileData={setEditProfileData}
          changePassword={changePassword}
          changePassData={changePassData}
          setChangePassData={setChangePassData}
        />
      </AppTemplate>}
    </main>
  );
}
  