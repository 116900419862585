import { useNavigate } from "react-router";
import styles from "./MaintenanceTemplate.module.css";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import img from '../../../assets/icons/maintenance.svg';

export default function MaintenanceTemplate(){

    const {signOut} = useContext(AuthContext);
    const navigate = useNavigate();

    return (
      <div className={styles.container}>
        <div className={styles.heading}>
            <div className={styles.imgContainer}>
                <img src={img}/>
            </div>
            <h3>Web en mantenimiento</h3>
        </div>
        <p>
            En este momento el sitio web se encuentra en mantenimiento.<br/>
            Lamentamos las molestias ocasionadas, intentá nuevamente más tarde.
        </p>
        <p 
            className={styles.button}
            onClick={() => signOut(navigate)}
        >
            Volver al inicio
        </p>
      </div>
    );
}
  