
import styles from './OptionButton.module.css';

export default function OptionButton({icon, title, onClick, style}){

    return (
        <div 
            className={[styles.container, styles[style]].join(' ')}
            onClick={onClick}
        >
            <img src={icon} alt="logo"/>
            <h4>{title}</h4>
        </div>
    );
}
  