import { useContext } from "react";
import AuthTemplate from "../../components/templates/auth/AuthTemplate/AuthTemplate";
import RecoverPasswordSuccessTemplate from "../../components/templates/auth/RecoverPasswordSuccessTemplate/RecoverPasswordSuccessTemplate";
import { recoverPasswordCodeForm } from "../../data/forms";


export default function RecoverPasswordSucces(){

  return (
    <main>
      <AuthTemplate>
        <RecoverPasswordSuccessTemplate />
      </AuthTemplate>
    </main>
  );
}