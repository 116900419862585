import { useContext } from "react";
import AuthTemplate from "../../components/templates/auth/AuthTemplate/AuthTemplate";
import RecoverPasswordTemplate from "../../components/templates/auth/RecoverPasswordTemplate/RecoverPasswordTemplate";
import { recoverPasswordNewPasswordForm } from "../../data/forms";
import { AuthContext } from "../../contexts/AuthContext";


export default function RecoverPasswordNewPassword(){

  const {recoverPasswordChangePassword, recoverPasswordNewPasswordData, setRecoverPasswordNewPasswordData} = useContext(AuthContext);

  return (
    <main>
      <AuthTemplate>
        <RecoverPasswordTemplate
          action={recoverPasswordChangePassword}
          apiData={recoverPasswordNewPasswordData}
          setApiData={setRecoverPasswordNewPasswordData}
          formData={recoverPasswordNewPasswordForm}
        />
      </AuthTemplate>
    </main>
  );
}