import Tab from 'react-bootstrap/Tab';
import {Tabs as BStabs} from 'react-bootstrap';
import styles from './Tabs.module.css';
import Table from '../../organisms/Table/Table';
import { adminMovementsTableHeadings } from '../../../data/tables';
import CardMovementAdmin from '../CardMovementAdmin/CardMovementAdmin';
import { useContext, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { useNavigate } from 'react-router';

export default function Tabs({apiData}){

    const {setMovementsType, key, setKey,} = useContext(AppContext);

    const navigate = useNavigate();

    const handleSelect = (key) => {
        setMovementsType(`/${key}`);
        setKey(key);
    }

    return (
        <div id="movementsTab">
            <BStabs
                id="movementsTab"
                className={styles.tabsContainer}
                justify
                activeKey={key}
                onSelect={(key) => handleSelect(key)}
            >
                <Tab 
                    className={styles.tabContainer} 
                    eventKey="all" 
                    title="Todas las inversiones"
                >
                    <Table
                        Card={CardMovementAdmin}
                        tableHeadings={adminMovementsTableHeadings}
                        tableContent={apiData}
                    />
                </Tab>
                <Tab 
                    className={styles.tabContainer} 
                    eventKey="manuals" 
                    title="Inversiones manuales"
                >
                    <Table
                        Card={CardMovementAdmin}
                        tableHeadings={adminMovementsTableHeadings}
                        tableContent={apiData}
                    />
                </Tab>
                <Tab 
                    className={styles.tabContainer} 
                    eventKey="automatic" 
                    title="Inversiones automáticas"
                >
                    <Table
                        Card={CardMovementAdmin}
                        tableHeadings={adminMovementsTableHeadings}
                        tableContent={apiData}
                    />
                </Tab>
            </BStabs>
        </div>
    );
}
  