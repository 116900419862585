import { useContext } from "react";
import AuthTemplate from "../../components/templates/auth/AuthTemplate/AuthTemplate";
import SignInTemplate from "../../components/templates/auth/SignInTemplate/SignInTemplate";
import { AuthContext } from "../../contexts/AuthContext";

export default function SignIn(){

  const {signIn, signInData, setSignInData} = useContext(AuthContext);

  return (
    <main>
      <AuthTemplate>
        <SignInTemplate
          signIn={signIn}
          signInData={signInData}
          setSignInData={setSignInData}
        />
      </AuthTemplate>
    </main>
  );
}
  