import Pagination from 'react-bootstrap/Pagination';

export default function Paginator({totalPages, totalCount, currentPage, action}) {

    let active = currentPage;
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item 
            key={number} 
            active={number == active}
            onClick={() => action({page: number})}
        >
          {number}
        </Pagination.Item>,
      );
    }

  return (
    <Pagination>
      {/* <Pagination.First 
        onClick={
            () => currentPage == 1 ? undefined : action({page: 1})
        } 
      /> */}
      <Pagination.Prev 
        onClick={
            () => currentPage == 1 ? undefined : action({page: +currentPage - 1})
        }
        disabled={currentPage == 1}
      />
        {items}
      <Pagination.Next 
        onClick={
            () => currentPage ==  totalPages ? undefined : action({page: +currentPage + 1})
        }
        disabled={currentPage == totalPages}
      />
      {/* <Pagination.Last 
        onClick={
            () => currentPage ==  totalPages ? undefined : action({page: totalPages})
        }
      /> */}
    </Pagination>
  );
}
