import { MONEY_FORMAT } from '../../../data/variables';
import styles from './CardDashboard.module.css';

export default function CardDashboard(props){

  const {bkIcon, title, subtitle, percentage, color} = props;

  return (
    <div className={[styles.card, styles[color]].join(' ')}>
        <div className={styles.overlay}>
          <h3>{title}</h3>
          <p>
            {subtitle == "-" ? subtitle : MONEY_FORMAT.format(+subtitle)}
          </p>
          {percentage && <p className={styles.percentage}>({percentage}%)</p>}
        </div>
        <img src={bkIcon} className={styles.bkIcon} alt="icon"/>
    </div>
  );
  
}
  
  