import { useContext } from "react";
import AuthTemplate from "../../components/templates/auth/AuthTemplate/AuthTemplate";
import RecoverPasswordTemplate from "../../components/templates/auth/RecoverPasswordTemplate/RecoverPasswordTemplate";
import { recoverPasswordCodeForm } from "../../data/forms";
import { AuthContext } from "../../contexts/AuthContext";


export default function RecoverPasswordCode(){

  const {recoverPasswordValidateCode, recoverPasswordCodeData, setRecoverPasswordCodeData} = useContext(AuthContext);

  return (
    <main>
      <AuthTemplate>
        <RecoverPasswordTemplate
          action={recoverPasswordValidateCode}
          apiData={recoverPasswordCodeData}
          setApiData={setRecoverPasswordCodeData}
          formData={recoverPasswordCodeForm}
        />
      </AuthTemplate>
    </main>
  );
}