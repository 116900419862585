import styles from './RecoverPasswordSuccessTemplate.module.css';
import { useNavigate } from 'react-router';

export default function RecoverPasswordSuccessTemplate(props){

  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        ¡Tu contraseña se cambió con éxito!
      </h2>
      <p className={styles.subtitle}>
        Volvé al inicio de sesión para ingresar.
      </p>
      <p 
        className={styles.button}
        onClick={() => navigate('/sign-in')}
      >
        Iniciar sesión
      </p>
    </div>
  );
}
  
  