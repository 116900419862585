// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormGroup_container__kxpL4{
    margin-bottom: 16px;
    display: flex;
    flex-direction: column !important;
    width: 100%;
}
.FormGroup_switchContainer__gl62H{
    justify-content: space-between;
    flex-direction: row !important;
    align-items: center;
}
.FormGroup_switchLabel__XWtFw{
    margin-bottom: 0 !important;
}
.FormGroup_halfColumn__vErzR{
    width:calc(50% - 6px);

}
.FormGroup_halfColumn__vErzR:nth-of-type(odd){
    margin-right: 12px;
}
.FormGroup_label__ELgIH{
    font-size: 14px;
    color: var(--primary-color);
    font-weight: 500;
    margin-bottom: 8px;
}
select{
  appearance:none;
}

.FormGroup_field__ZTfx\\+{
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--light-gray);
    background-color: white;
}

@media (max-width:576px) {
    .FormGroup_halfColumn__vErzR{
        width:100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/FormGroup/FormGroup.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,iCAAiC;IACjC,WAAW;AACf;AACA;IACI,8BAA8B;IAC9B,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,qBAAqB;;AAEzB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,2BAA2B;IAC3B,gBAAgB;IAChB,kBAAkB;AACtB;AACA;EAGE,eAAe;AACjB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,mCAAmC;IACnC,uBAAuB;AAC3B;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".container{\n    margin-bottom: 16px;\n    display: flex;\n    flex-direction: column !important;\n    width: 100%;\n}\n.switchContainer{\n    justify-content: space-between;\n    flex-direction: row !important;\n    align-items: center;\n}\n.switchLabel{\n    margin-bottom: 0 !important;\n}\n.halfColumn{\n    width:calc(50% - 6px);\n\n}\n.halfColumn:nth-of-type(odd){\n    margin-right: 12px;\n}\n.label{\n    font-size: 14px;\n    color: var(--primary-color);\n    font-weight: 500;\n    margin-bottom: 8px;\n}\nselect{\n  -webkit-appearance: none; \n  -moz-appearance:none;\n  appearance:none;\n}\n\n.field{\n    padding: 12px;\n    border-radius: 8px;\n    border: 1px solid var(--light-gray);\n    background-color: white;\n}\n\n@media (max-width:576px) {\n    .halfColumn{\n        width:100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FormGroup_container__kxpL4`,
	"switchContainer": `FormGroup_switchContainer__gl62H`,
	"switchLabel": `FormGroup_switchLabel__XWtFw`,
	"halfColumn": `FormGroup_halfColumn__vErzR`,
	"label": `FormGroup_label__ELgIH`,
	"field": `FormGroup_field__ZTfx+`
};
export default ___CSS_LOADER_EXPORT___;
