// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileTemplate_container__K0p7o{
    padding: 60px 60px 120px 60px;
    position: relative;
    height: 100%;
    overflow-y: scroll;
}
.ProfileTemplate_heading__CaWv7{
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 500;
}
.ProfileTemplate_heading__CaWv7 h1{
    font-size: 52px;
    font-weight: 600;
    color: var(--primary-dark-color);
}
.ProfileTemplate_contentContainer__cc046{
    display: flex;
}
.ProfileTemplate_content__6OdAC{
    width: 50%;
    position: relative;
    z-index:500;
}
.ProfileTemplate_content__6OdAC:first-of-type{
    padding-right: 24px;
}
.ProfileTemplate_content__6OdAC:last-of-type{
    padding-left: 24px;
}
.ProfileTemplate_title__q4jOS{
    font-size: 24px;
    font-weight: 500;
    margin: 12px 0px 24px 0px;
}


@media (max-width: 576px) {
    .ProfileTemplate_container__K0p7o{
        padding: 30px 20px 65px 20px;
        position: unset;
    }
    .ProfileTemplate_contentContainer__cc046{
        flex-direction: column;
    }
    .ProfileTemplate_heading__CaWv7{
        flex-direction: column;
        margin-bottom: 12px;
    }
    .ProfileTemplate_heading__CaWv7 h1{
        font-size: 32px;
    }
    .ProfileTemplate_heading__CaWv7 button{
        padding: 16px 24px !important;
        margin-top: 12px;
    }
    .ProfileTemplate_content__6OdAC{
        width: 100%;
        padding:0 !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/templates/users/ProfileTemplate/ProfileTemplate.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,mBAAmB;IACnB,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,gCAAgC;AACpC;AACA;IACI,aAAa;AACjB;AACA;IACI,UAAU;IACV,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,yBAAyB;AAC7B;;;AAGA;IACI;QACI,4BAA4B;QAC5B,eAAe;IACnB;IACA;QACI,sBAAsB;IAC1B;IACA;QACI,sBAAsB;QACtB,mBAAmB;IACvB;IACA;QACI,eAAe;IACnB;IACA;QACI,6BAA6B;QAC7B,gBAAgB;IACpB;IACA;QACI,WAAW;QACX,oBAAoB;IACxB;AACJ","sourcesContent":[".container{\n    padding: 60px 60px 120px 60px;\n    position: relative;\n    height: 100%;\n    overflow-y: scroll;\n}\n.heading{\n    margin-bottom: 32px;\n    display: flex;\n    justify-content: space-between;\n    position: relative;\n    z-index: 500;\n}\n.heading h1{\n    font-size: 52px;\n    font-weight: 600;\n    color: var(--primary-dark-color);\n}\n.contentContainer{\n    display: flex;\n}\n.content{\n    width: 50%;\n    position: relative;\n    z-index:500;\n}\n.content:first-of-type{\n    padding-right: 24px;\n}\n.content:last-of-type{\n    padding-left: 24px;\n}\n.title{\n    font-size: 24px;\n    font-weight: 500;\n    margin: 12px 0px 24px 0px;\n}\n\n\n@media (max-width: 576px) {\n    .container{\n        padding: 30px 20px 65px 20px;\n        position: unset;\n    }\n    .contentContainer{\n        flex-direction: column;\n    }\n    .heading{\n        flex-direction: column;\n        margin-bottom: 12px;\n    }\n    .heading h1{\n        font-size: 32px;\n    }\n    .heading button{\n        padding: 16px 24px !important;\n        margin-top: 12px;\n    }\n    .content{\n        width: 100%;\n        padding:0 !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProfileTemplate_container__K0p7o`,
	"heading": `ProfileTemplate_heading__CaWv7`,
	"contentContainer": `ProfileTemplate_contentContainer__cc046`,
	"content": `ProfileTemplate_content__6OdAC`,
	"title": `ProfileTemplate_title__q4jOS`
};
export default ___CSS_LOADER_EXPORT___;
