
import styles from './Table.module.css';
import triangle from '../../../assets/icons/triangle.svg';
import { useEffect, useState } from 'react';

export default function Table(props){

  const {tableHeadings, tableContent, Card, columns} = props;

  const [results, setResults] = useState();

  useEffect(() => {
    setResults(tableContent);
  },[tableContent])


  const handleClick = (type, order) => {
    if(type == "instrument" && order == 'asc'){
      const newResults = [...results]?.sort((a,b) =>{
        const nombreA = a.instrument.toLowerCase();
        const nombreB = b.instrument.toLowerCase();
        if (nombreA < nombreB) {
          return -1;
        }
        if (nombreA > nombreB) {
          return 1;
        }
        return 0;
      })
      setResults(newResults)
    }else if (type == "instrument" && order == 'desc'){
      const newResults = [...results]?.sort((a,b) =>{
        const nombreA = a.instrument.toLowerCase();
        const nombreB = b.instrument.toLowerCase();
        if (nombreA > nombreB) {
          return -1;
        }
        if (nombreA < nombreB) {
          return 1;
        }
        return 0;
      })
      setResults(newResults);
    }else if (type == "startDate" && order == 'asc'){
      const newResults = [...results]?.sort((a,b) =>{
        const dateA = new Date(a.dateOfAdmission);
        const dateB = new Date(b.dateOfAdmission);
        return dateA - dateB;
      })
      setResults(newResults);
    }else if (type == "startDate" && order == 'desc'){
      const newResults = [...results]?.sort((a,b) =>{
        const dateA = new Date(a.dateOfAdmission);
        const dateB = new Date(b.dateOfAdmission);
        return dateB - dateA;
      })
      setResults(newResults);
    }else if (type == "endDate" && order == 'asc'){
      const newResults = [...results]?.sort((a,b) =>{
        const dateA = new Date(a.returnDate);
        const dateB = new Date(b.returnDate);
        return dateA - dateB;
      })
      setResults(newResults);
    }else if (type == "endDate" && order == 'desc'){
      const newResults = [...results]?.sort((a,b) =>{
        const dateA = new Date(a.returnDate);
        const dateB = new Date(b.returnDate);
        return dateB - dateA;
      })
      setResults(newResults);
    }
  }

  return (
    <>
      <div className={styles.table}>
        {tableHeadings.map((heading,i) => (
          <div key={i} className={styles.item} style={{width: `calc(100% / ${columns})`}}>
            <p>{heading.label}</p>
            {heading.id == "instrument" && 
              <span className={styles.arrowContainer}>
                <img src={triangle} 
                  className={styles.upArrow} 
                  onClick={() => handleClick('instrument', 'asc')}
                  alt="triangle"
                />
                <img src={triangle} 
                  className={styles.downArrow} 
                  onClick={() => handleClick('instrument', 'desc')}
                  alt="triangle"
                />
              </span>
            }
            {heading.id == "startDate" && 
              <span className={styles.arrowContainer}>
                <img 
                  src={triangle} 
                  className={styles.upArrow} 
                  alt="triangle"
                  onClick={() => handleClick('startDate', 'asc')}
                />
                <img 
                  src={triangle} 
                  className={styles.downArrow} 
                  onClick={() => handleClick('startDate', 'desc')}
                  alt="triangle"
                />
              </span>
            }
            {heading.id == "endDate" && 
              <span className={styles.arrowContainer}>
                <img 
                  src={triangle} 
                  className={styles.upArrow}
                  onClick={() => handleClick('endDate', 'asc')}
                  alt="triangle"
                />
                <img 
                  src={triangle} 
                  className={styles.downArrow} 
                  onClick={() => handleClick('endDate', 'desc')}
                  alt="triangle"
                />
              </span>
            }
          </div>
        ))}
      </div>
      <div className={styles.content}>
        {results?.length == 0 &&
          <p className={styles.noResults}>No se encontraron datos que coincidan con el criterio de búsqueda.</p>
        }
        {results?.map((movement, i) => (
          <Card key={i} {...movement}/>
        ))}
      </div>
    </>
  );
  }
  
  