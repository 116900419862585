// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecoverPasswordTemplate_container__4qgoM{
    width: 100%;
}

.RecoverPasswordTemplate_title__7nk4S{
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    color: var(--title);
    margin-top: 12px;
    text-align: left;
}
.RecoverPasswordTemplate_subtitle__Hn2RD{
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: grey;
    margin: 10px 0px 20px 0px;
    text-align: left;
}

@media (max-width:576px) {
   .RecoverPasswordTemplate_title__7nk4S{
        font-size: 18px;
        line-height: 20px;
        text-align: center;
   }
   .RecoverPasswordTemplate_subtitle__Hn2RD{
        font-size: 14px;
        line-height: 18px;
        margin: 10px 0px 20px 0px;
        text-align: center;
   }
}`, "",{"version":3,"sources":["webpack://./src/components/templates/auth/RecoverPasswordTemplate/RecoverPasswordTemplate.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;GACG;QACK,eAAe;QACf,iBAAiB;QACjB,kBAAkB;GACvB;GACA;QACK,eAAe;QACf,iBAAiB;QACjB,yBAAyB;QACzB,kBAAkB;GACvB;AACH","sourcesContent":[".container{\n    width: 100%;\n}\n\n.title{\n    font-size: 24px;\n    line-height: 30px;\n    font-weight: 600;\n    color: var(--title);\n    margin-top: 12px;\n    text-align: left;\n}\n.subtitle{\n    font-size: 14px;\n    line-height: 20px;\n    font-weight: 400;\n    color: grey;\n    margin: 10px 0px 20px 0px;\n    text-align: left;\n}\n\n@media (max-width:576px) {\n   .title{\n        font-size: 18px;\n        line-height: 20px;\n        text-align: center;\n   }\n   .subtitle{\n        font-size: 14px;\n        line-height: 18px;\n        margin: 10px 0px 20px 0px;\n        text-align: center;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RecoverPasswordTemplate_container__4qgoM`,
	"title": `RecoverPasswordTemplate_title__7nk4S`,
	"subtitle": `RecoverPasswordTemplate_subtitle__Hn2RD`
};
export default ___CSS_LOADER_EXPORT___;
