import styles from './CardMovementAdmin.module.css';
import users from '../../../assets/icons/users.svg'
import { getDate } from '../../../utils/dateFormatter';
import edit from '../../../assets/icons/edit.svg';
import trash from '../../../assets/icons/trash.svg';
import { useNavigate } from 'react-router';
import { MONEY_FORMAT } from '../../../data/variables';
import Modal from '../Modal/Modal';
import { useContext, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';

export default function CardMovementAdmin(props){

  const {refundAmount, money, annualRent, dateOfAdmission, returnDate, instrument, user, commissions, id, isAutomatic, agreedPercentage,monthlyRent,percentageYield, active} = props;

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const {deleteUser} = useContext(AppContext);


    return (
      <div className={styles.card}>
        <div className={styles.firstItem}>
          {/* <div className={[styles.icon, styles[instrument == "cash" ? "primary" : instrument == "crypto" ? "secondary" : "tertiary"]].join(' ')}>
            <img src={users} alt="icon"/>
          </div> */}
          <div className={styles.item}>
            <h5 className={styles.title}>Usuario</h5>
            <p className={styles.value}>{user?.firstName} {user?.lastName}</p>
          </div>
        </div>
        <div className={styles.item}>
          <h5 className={styles.title}>Instrumento</h5>
          <p className={styles.value}>{instrument}</p>
        </div>
        <div className={styles.item}>
          <h5 className={styles.title}>Capital</h5>
          <p className={styles.value}>{MONEY_FORMAT.format(+money)}</p>
        </div>
        <div className={styles.item}>
          <h5 className={styles.title}>Fee</h5>
          <p className={styles.value}>{MONEY_FORMAT.format(+commissions) || "-"}</p>
        </div>
        <div className={styles.item}>
          <h5 className={styles.title}>Rendimiento anual</h5>
          <p className={styles.value}>{MONEY_FORMAT.format(+annualRent)} <span>({agreedPercentage}%)</span></p>
        </div>
        <div className={styles.item}>
          <h5 className={styles.title}>Rendimiento mensual</h5>
          <p className={styles.value}>{MONEY_FORMAT.format(+monthlyRent)} <span>({percentageYield}%)</span></p>
        </div>
        <div className={styles.item}>
          <h5 className={styles.title}>Devolución</h5>
          <p className={styles.value}>{MONEY_FORMAT.format(+refundAmount)}</p>
        </div>
        <div className={styles.item}>
          <h5 className={styles.title}>Fecha admisión</h5>
          <p className={styles.value}>{getDate(dateOfAdmission)}</p>
        </div>
        <div className={styles.item}>
          <h5 className={styles.title}>Fecha final</h5>
          <p className={styles.value}>{getDate(returnDate) || "-"}</p>
        </div>
        <div className={styles.item}>
          <h5 className={styles.title}>Estado</h5>
          <p className={active ? styles.activeBadge : styles.inactiveBadge}>
            <div></div>
            {active ? "Activa" : "Finalizada"}
          </p>
        </div>
        <div className={styles.itemActions}>
          <h5 className={styles.title}>Acciones</h5>
          <div className={styles.actions}>
            <img 
              src={edit} 
              onClick={() => navigate(`/admin/app/movements/${id}`)}
              alt="icon"
            />
            {/* <img 
              src={trash} 
              onClick={() => setShowModal(true)}
              alt="icon"
            /> */}
          </div>
        </div>
        {/* <Modal
          show={showModal}
          setShow={setShowModal}
          handleAction={() => deleteUser(id, navigate)}
          deleteText="esta inversión"
        /> */}
      </div>
    );
  }
  
  