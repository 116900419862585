import styles from './GraphicDashboard.module.css';
import { Doughnut } from 'react-chartjs-2';
import {Chart, ArcElement, Tooltip, Legend } from 'chart.js'
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { MONEY_FORMAT } from '../../../data/variables';
Chart.register(ArcElement, Tooltip, Legend );

export default function GraphicDashboard(props){

  const {title} = props;
  const [referencesPosition, setReferencesPosition] = useState('right');
  const {userInstruments} = useContext(AppContext);

  useEffect(() => {
    window.innerWidth < 1500 && setReferencesPosition('bottom')
  },[]);

  const graphicData ={
    labels: userInstruments?.data?.map((instrument) => {return [instrument?.instrument]}),
    datasets:[
        {
            data: userInstruments?.data?.map((instrument) => {return [instrument?.total]}),
            backgroundColor: [
              '#2850EA',
              "#6994f1e8",
              '#bccbef',
              '#202336',
              '#1C3673',
              '#4B5381',
              '#003DCB',
              '#334265',
              'B8C2E0',
              '#0030C2'
            ],
            borderWidth:0,
        }
    ],
  }

    return (
      <div className={styles.container}>
        <h3>{title}</h3>
        <div className={styles.graphContainer}>
          <Doughnut
            width={250}
            height={250}
            data={graphicData}
            options={{
              maintainAspectRatio: false,
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      return [`$${context.formattedValue}`]
                    }
                  }
                },
                legend: {
                  position: referencesPosition,
                  onClick:null,
                  labels: {
                    font: {
                      size: 16,
                      weight: 400,
                    },
                    color: "#2D3748",
                    boxWidth: 20,
                    boxHeight: 20,
                    usePointStyle: true,
                    pointStyle: "square",
                    padding: 20,
                    generateLabels(chart) {
                      const data = chart.data;
                      if (data.labels.length && data.datasets.length) {
                        const {
                          labels: {
                            pointStyle
                          }
                        } = chart.legend.options;
          
                        return data.labels.map((label, i) => {
                          const meta = chart.getDatasetMeta(0);
                          const style = meta.controller.getStyle(i);
                          let typerOne = `${MONEY_FORMAT.format(data['datasets'][0].data[i])}`;
                          let typerTwo = `${label}`;
                          return {
                            text: `${typerTwo} - ${typerOne}`,
                            fillStyle: style.backgroundColor,
                            strokeStyle: style.borderColor,
                            lineWidth: style.borderWidth,
                            pointStyle: pointStyle,
                            hidden: !chart.getDataVisibility(i),
                            index: i
                          };
                        });
                      }
                      return [];
                    }
                  }
                },
              }
            }}
          /> 
        </div>   
      </div>
    );
  }
  
  