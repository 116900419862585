import { useEffect, useState } from 'react';
import Menu from '../../organisms/Menu/Menu';
import styles from './AppTemplate.module.css';
import MaintenanceTemplate from '../MaintenanceTemplate/MaintenanceTemplate';

export default function AppTemplate({routes, children}){

  const [showMaintenance, setShowMaintenance] = useState(false);

  useEffect(() => {
   const user = JSON.parse(localStorage.getItem('user'));
   const isMaintenanceActive = JSON.parse(localStorage.getItem('isMaintenanceActive'));
   if(user.role.id != 1 && isMaintenanceActive){
    setShowMaintenance(true)
   }else{
    setShowMaintenance(false);
   }
  },[])

  return (
    showMaintenance ? 
    <MaintenanceTemplate/> :
    <div className={styles.container}>
        <Menu routes={routes} />
        <div className={styles.content}>
            {children}
        </div>
        {/* <Toast
            toast={toast}
            setToast={setToast}
            content={toast.content}
        /> */}
    </div>
  );
}
  
  
