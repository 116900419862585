import Loader from "../../../components/atoms/Loader/Loader";
import AppTemplate from "../../../components/templates/AppTemplate/AppTemplate";
import UsersTemplate from "../../../components/templates/admin/UsersTemplate/UsersTemplate";
import { adminAppRoutes } from "../../../data/routes";
import {usersTestData} from "../../../data/example";
import { AppContext } from "../../../contexts/AppContext";
import { useContext, useEffect } from "react";


export default function Users(){

  const {getUsers, users, loading} = useContext(AppContext);

  useEffect(() => {
    getUsers();
  },[])


  return (
    <main>
      {loading ?
        <Loader/> :
        <AppTemplate routes={adminAppRoutes}>
          <UsersTemplate 
            users={users?.data}
            pagination={users?.meta}
          />
        </AppTemplate>
      }
    </main>
  );
}
  