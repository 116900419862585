// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OptionButton_container__EdkIn{
    background-color: white;
    width: 32%;
    padding: 14px;
    border-radius: 12px;
    border: 1px solid var(--primary-color);
    margin: 0.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
}
.OptionButton_container__EdkIn:hover,
.OptionButton_active__9klaG{
    background-color: #dee7fc;
    color: var(--primary-color) !important;
    transition: all 0.3s ease;
}

.OptionButton_container__EdkIn h4{
    font-size: 16px;
    text-align: center;
}
.OptionButton_container__EdkIn img{
    width: 30%;
    margin-bottom: 20px;
    filter: var( --primary-filter);
}

@media (max-width: 576px) {
    .OptionButton_container__EdkIn{
        width: 100%;
        padding: 12px;
        border-radius: 8px;
        margin: 0 0 2% 0;
        flex-direction: row;
        justify-content: flex-start;
    }
    .OptionButton_container__EdkIn h4{
        font-size: 16px;
        text-align: left;
        margin-left: 8px;
        margin-bottom: 0;
    }
    .OptionButton_container__EdkIn img{
        width: 10%;
        margin-bottom: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/OptionButton/OptionButton.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,sCAAsC;IACtC,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,yBAAyB;AAC7B;AACA;;IAEI,yBAAyB;IACzB,sCAAsC;IACtC,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI;QACI,WAAW;QACX,aAAa;QACb,kBAAkB;QAClB,gBAAgB;QAChB,mBAAmB;QACnB,2BAA2B;IAC/B;IACA;QACI,eAAe;QACf,gBAAgB;QAChB,gBAAgB;QAChB,gBAAgB;IACpB;IACA;QACI,UAAU;QACV,kBAAkB;IACtB;AACJ","sourcesContent":[".container{\n    background-color: white;\n    width: 32%;\n    padding: 14px;\n    border-radius: 12px;\n    border: 1px solid var(--primary-color);\n    margin: 0.5%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    transition: all 0.3s ease;\n}\n.container:hover,\n.active{\n    background-color: #dee7fc;\n    color: var(--primary-color) !important;\n    transition: all 0.3s ease;\n}\n\n.container h4{\n    font-size: 16px;\n    text-align: center;\n}\n.container img{\n    width: 30%;\n    margin-bottom: 20px;\n    filter: var( --primary-filter);\n}\n\n@media (max-width: 576px) {\n    .container{\n        width: 100%;\n        padding: 12px;\n        border-radius: 8px;\n        margin: 0 0 2% 0;\n        flex-direction: row;\n        justify-content: flex-start;\n    }\n    .container h4{\n        font-size: 16px;\n        text-align: left;\n        margin-left: 8px;\n        margin-bottom: 0;\n    }\n    .container img{\n        width: 10%;\n        margin-bottom: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `OptionButton_container__EdkIn`,
	"active": `OptionButton_active__9klaG`
};
export default ___CSS_LOADER_EXPORT___;
