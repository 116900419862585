// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_container__3f9KY{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top:0;
    z-index: 900000000000000000000000;
    background-color: var(--white);
    width: 100vw;
    height: 100vh;
}

.Loader_container__3f9KY img{
    width: 250px;
    animation-name: Loader_loaderMovement__9D266;
    animation-duration: 0.8s;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes Loader_loaderMovement__9D266 {
    0%{
        opacity: 1;
        transform: translateY(10px);
    }
    100%{
        opacity: 0.8;
        transform: translateY(-10px);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/Loader/Loader.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,KAAK;IACL,iCAAiC;IACjC,8BAA8B;IAC9B,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,4CAA8B;IAC9B,wBAAwB;IACxB,kCAAkC;IAClC,mCAAmC;IACnC,8BAA8B;AAClC;;AAEA;IACI;QACI,UAAU;QACV,2BAA2B;IAC/B;IACA;QACI,YAAY;QACZ,4BAA4B;IAChC;AACJ","sourcesContent":[".container{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: fixed;\n    top:0;\n    z-index: 900000000000000000000000;\n    background-color: var(--white);\n    width: 100vw;\n    height: 100vh;\n}\n\n.container img{\n    width: 250px;\n    animation-name: loaderMovement;\n    animation-duration: 0.8s;\n    animation-timing-function: ease-in;\n    animation-iteration-count: infinite;\n    animation-direction: alternate;\n}\n\n@keyframes loaderMovement {\n    0%{\n        opacity: 1;\n        transform: translateY(10px);\n    }\n    100%{\n        opacity: 0.8;\n        transform: translateY(-10px);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Loader_container__3f9KY`,
	"loaderMovement": `Loader_loaderMovement__9D266`
};
export default ___CSS_LOADER_EXPORT___;
