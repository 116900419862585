import {useEffect, useState} from 'react'
import styles from './MovementsFormTemplate.module.css';
import back from '../../../../assets/icons/back.svg';
import dollars from '../../../../assets/icons/dollars.svg';
import dollar from '../../../../assets/icons/dollar.svg';
import Form from '../../../organisms/Form/Form';
import { useNavigate } from 'react-router';
import Toast from '../../../atoms/Toast/Toast';
import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import Button from '../../../atoms/Button/Button';
import ModalOptions from '../../../molecules/ModalOptions/ModalOptions';
import FormGroup from '../../../molecules/FormGroup/FormGroup';


export default function MovementsFormTemplate(props){

  const navigate = useNavigate();
  const {title, action, setApiData, apiData, formData, id, users, disabled} = props;
  const {toast, setToast, withdrawalStatus, withdrawMoneyFromInvestment} = useContext(AppContext);

  const [showModal, setShowModal] = useState(false);
  const [partialWithdrawalAmounts, setPartialWithdrawalAmounts] = useState({
    investmentsAmount:"0",
    interestAmount:"0",
    active: true,
  });
  const [showContent, setShowContent] = useState({
    options:true, content:false, status:false, option: 'all'
  });

  const handleClick = (option) => {
    if(option === 'total'){
      withdrawMoneyFromInvestment(
        id, 
        {withdrawType:'total'}, 
        navigate,
        () => setShowModal(false)
      );
    }else{
      withdrawMoneyFromInvestment(
        id, 
        {
          withdrawType:'partial',
          totalInvestmentWithdrawal: +partialWithdrawalAmounts.investmentsAmount,
          totalInterestWithdrawal: +partialWithdrawalAmounts.interestAmount,
          active: partialWithdrawalAmounts.active
        }, 
        navigate,
        () => setShowModal(false)
      );
    }
  }


  const formFields = formData.formFields?.map(field => {
      if(field.id == 'userId'){
        return{
          ...field, 
          options:users?.map(user => {
            return {
              id: "userId",
              label: `${user?.firstName} ${user?.lastName}`,
              value: user?.id
            }
          })
        }
      }else if(field.id == 'isAutomatic'){
        return {
          ...field, 
          options:[
            {
              id: "isAutomatic",
              label: "Automática",
              value: true
            },
            {
              id: "isAutomatic",
              label: "Manual",
              value: false
            }
          ]
        }
      }else{
        return {...field}
      }
  })

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.headingContainer}>
          <div 
            className={styles.backButton}
            onClick={() => navigate('/admin/app/movements')}
          >
            <img 
              src={back} 
              className={styles.back}
              alt="back"
            />
            <p>Atrás</p>
          </div>
          <h1>{title}</h1>
        </div>
        {id &&
          <div className={styles.buttonContainer}>
            <Button
              text="Retirar dinero"
              style="primary"
              size="sm"
              handleClick={() => setShowModal(true)}
            />
          </div>
        }
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <Form 
            formFields={formFields}
            button={{
              ...formData.button,
              handleClick: id ? () => action(apiData, id, navigate) : () => action(apiData, navigate),
              disabled: disabled
            }}
            setApiData={setApiData}
            apiData={apiData}
          />
        </div>
      </div>
      <Toast
        toast={toast}
        setToast={setToast}
        content={toast?.content}
      />
      <ModalOptions
        show={showModal}
        setShow={setShowModal}
        showContent={showContent}
        setShowContent={setShowContent}
        options={[
          {
            id:"partial",
            icon:dollar,
            title:"Retiro parcial"
          },
          {
            id:"total",
            icon:dollars,
            title:"Retiro total",
          },
        ]}
      >
        {showContent?.option != "all" &&
          <>
          <p><b>¿Estás seguro de hacer un retiro de {showContent?.option == "total" ? "la totalidad" : "una parte"} del dinero del usuario?</b></p>
          <p style={{marginTop: 5, marginBottom: 26}}>
          {showContent?.option == "total" ? 
            "Esta acción no puede deshacerse" : 
            "Seleccioná la cantidad de dinero a retirar según corresponda:"
          }
          </p>
          {showContent?.option == "partial" &&
            <div className={styles.formGroup}>
              <FormGroup
                cols={12}
                label="Cant. de dinero invertido"
                input="text"
                tag="input"
                placeholder="1200.50"
                id="investmentsAmount"
                value={partialWithdrawalAmounts.investmentsAmount}
                setApiData={setPartialWithdrawalAmounts}
                apiData={partialWithdrawalAmounts}
              />
              <FormGroup
                cols={12}
                label="Cant. de intereses generados"
                input="text"
                tag="input"
                placeholder="600.10"
                id="interestAmount"
                value={partialWithdrawalAmounts.interestAmount}
                setApiData={setPartialWithdrawalAmounts}
                apiData={partialWithdrawalAmounts}
              />
              <FormGroup
                cols={12}
                label="Activar/Finalizar inversión"
                tag="switch"
                id="active"
                value={partialWithdrawalAmounts.active}
                setApiData={setPartialWithdrawalAmounts}
                apiData={partialWithdrawalAmounts}
              />
            </div>
          }
          <Button 
            text={withdrawalStatus == "loading" ? 
              "Retirando dinero..." : 
              withdrawalStatus == "success" ?
              "¡Dinero retirado con éxito!" :
              `Retirar dinero ${showContent?.option == "total" ? "total" : "parcial"}`
            }
            style={withdrawalStatus == "loading" ? 
            "disabled" :
            withdrawalStatus == "success" ?
            "success" :
            "primary"}
            size="sm"
            handleClick={(withdrawalStatus == "loading" || withdrawalStatus == "success") ? 
            undefined : 
            () => handleClick(showContent?.option)}
          />
        </>
        }
      </ModalOptions>
    </div>
  );
}
  
  