
import styles from './Loader.module.css';
import logo from '../../../assets/logos/isologo.svg';

export default function Loader(){

    return (
        <div className={styles.container}>
            <img src={logo} alt="logo"/>
        </div>
    );
}
  