import axios from 'axios';
import { API_URL } from '../data/variables';

export const initAxiosInterceptor = () => {
  //Axios - defaults
  axios.defaults.baseURL = API_URL;

  //Axios - Request
  axios.interceptors.request.use(
    async (config) => {
      if (!config.headers.Authorization) {
        let accessToken = localStorage.getItem('token');
        if (accessToken && accessToken !== 'null') {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  //Axios - Response
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function axiosRetryInterceptor(err) {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.replace("/");
      }
      return Promise.reject(err);
    }
  );

  async function refreshTokens(refreshToken){
    let config = {
      headers: {
        'Authorization': 'Bearer ' + refreshToken
      }

    }
    return axios.get("/auth/refresh", config);
  }

}


