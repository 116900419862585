import { useContext, useEffect } from "react";
import Loader from "../../components/atoms/Loader/Loader";
import AppTemplate from "../../components/templates/AppTemplate/AppTemplate";
import { usersAppRoutes } from "../../data/routes";
import { AppContext } from "../../contexts/AppContext";
import ActivityTemplate from "../../components/templates/users/ActivityTemplate/ActivityTemplate";


export default function Activity(){

  const {loading, getActivity, userActivity} = useContext(AppContext);

  useEffect(() => {
    getActivity();
  },[])

  return (
    <main>
      {loading ? 
        <Loader/> :
        <AppTemplate routes={usersAppRoutes}>
          <ActivityTemplate/>
        </AppTemplate>
      }
    </main>
  );
}
  