
export const userDashboardFilters = {
    formFields:[
        {
            label:"Desde",
            input: "date",
            tag: "input",
            id:"dateFrom"
        },
        {
            label:"Hasta",
            input: "date",
            tag: "input",
            id:"dateUntil"
        },
        {
            label:"Instrumento",
            input: "text",
            tag: "input",
            id:"instrument",
            placeholder:"Ej: cash"
        },
        {
            label:"Capital invertido",
            input: "text",
            tag: "input",
            id:"capital",
            placeholder:"3000"
        },
    ],
    button:{
        text:"Aplicar filtros",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}

export const userDocsFiltersData = {
    formFields:[
        {
            label:"Desde",
            input: "date",
            tag: "input",
            id:"dateFrom"
        },
        {
            label:"Hasta",
            input: "date",
            tag: "input",
            id:"dateUntil"
        },
        {
            label:"Tipo de documento",
            tag: "select",
            id:"type",
            placeholder:"Ej: reporte",
            options:[
                {
                    value:"report",
                    id:"type",
                    label:"Reporte",
                },
                {
                    value:"invoice",
                    id:"type",
                    label:"Invoice",
                },
            ]
        },
    ],
    button:{
        text:"Aplicar filtros",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}

export const adminUsersFiltersData = {
    formFields:[
        {
            label:"Búsqueda",
            input: "text",
            tag: "input",
            placeholder:"Ej: Nombre, apellido, etc.",
            id:"search"
        },
    ],
    button:{
        text:"Aplicar filtros",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}

export const adminMovementsFiltersData = {
    formFields:[
        {
            label:"Desde",
            input: "date",
            tag: "input",
            id:"dateFrom"
        },
        {
            label:"Hasta",
            input: "date",
            tag: "input",
            id:"dateUntil"
        },
        {
            label:"Nombre de usuario",
            input: "text",
            tag: "input",
            placeholder:"Ej: Nombre, apellido",
            id:"search"
        },
        {
            label:"Id de usuario",
            input: "number",
            tag: "input",
            placeholder:"Ej: 1",
            id:"user"
        },
        {
            label:"Tipo de movimiento",
            tag: "select",
            id:"typeMovement",
            options:[
                {
                    value:"withdrawal",
                    id:"typeMovement",
                    label:"Retiro",
                },
                {
                    value:"deposit",
                    id:"typeMovement",
                    label:"Depósito",
                },
                {
                    value:"comission",
                    id:"typeMovement",
                    label:"Fee",
                },
            ]
        },
    ],
    button:{
        text:"Aplicar filtros",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}