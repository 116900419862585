import styles from './CardUser.module.css';
import users from '../../../assets/icons/users.svg';
import edit from '../../../assets/icons/edit.svg';
import trash from '../../../assets/icons/trash.svg';
import { useNavigate } from 'react-router';
import { useContext, useState } from 'react';
import Modal from '../Modal/Modal';
import { AppContext } from '../../../contexts/AppContext';

export default function CardUser(props){

    const {firstName, lastName, email, phoneNumber, id} = props;
    const {deleteUser} = useContext(AppContext);

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    return (
      <div className={styles.card}>
        <div className={styles.itemsContainer}>
          <div className={styles.firstItem}>
            <div className={styles.icon}>
              <p>#{id}</p>
            </div>
            <div className={styles.item}>
            <h5 className={styles.title}>Nombre</h5>
              <p className={styles.value}>{firstName} {lastName}</p>
            </div>
          </div>
          <div className={styles.item}>
            <h5 className={styles.title}>Email</h5>
            <p className={styles.value}>{email}</p>
          </div>
          <div className={styles.item}>
            <h5 className={styles.title}>Teléfono</h5>
            <p className={styles.value}>{phoneNumber}</p>
          </div>
        </div>
        <div className={styles.itemActions}>
          <h5 className={styles.title}>Acciones</h5>
          <div className={styles.actions}>
            <img 
              src={edit} 
              onClick={() => navigate(`/admin/app/users/${id}`)}
              alt="icon"
            />
            <img 
              src={trash} 
              alt="icon"
              onClick={() => setShowModal(true)}
            />
          </div>
        </div>
        <Modal
          show={showModal}
          setShow={setShowModal}
          handleAction={() => deleteUser(id, navigate)}
          deleteText="este usuario"
        />
      </div>
    );
  }
  
  