import Form from '../../../organisms/Form/Form';
import styles from './RecoverPasswordTemplate.module.css';
import { useNavigate } from 'react-router';

export default function RecoverPasswordTemplate(props){

  const {action, apiData, setApiData, formData, sendCode = false} = props;

  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        {formData.title}
      </h2>
      <p className={styles.subtitle}>
        {formData.subtitle}
      </p>
      <Form 
        formFields={formData.formFields}
        button={{
          ...formData.button,
          handleClick: () => action(apiData, navigate)
        }}
        setApiData={setApiData}
        apiData={apiData}
        sendCode={sendCode}
      />
    </div>
  );
}
  
  