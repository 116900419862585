import styles from './CardActivity.module.css';
import dollar from '../../../assets/icons/dollar.svg';
import { useNavigate } from 'react-router';
import { useContext, useState } from 'react';
import Modal from '../Modal/Modal';
import { AppContext } from '../../../contexts/AppContext';
import { getDate } from '../../../utils/dateFormatter';
import { MONEY_FORMAT } from '../../../data/variables';

export default function CardActivity(props){

    const {createdAt, money, typeMovement} = props;
    const {deleteUser} = useContext(AppContext);

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    return (
      <div className={styles.card}>
        <div className={styles.itemsContainer}>
          <div className={styles.firstItem}>
            <div className={styles.icon}>
              <img src={dollar} alt="icon"/>
            </div>
            <div className={styles.item}>
              <h5 className={styles.title}>Tipo de movimiento</h5>
              <p className={styles.value}>{typeMovement}</p>
            </div>
          </div>
          <div className={styles.item}>
            <h5 className={styles.title}>Monto</h5>
            <p className={styles.value}>{MONEY_FORMAT.format(+money)}</p>
          </div>
          <div className={styles.item}>
              <h5 className={styles.title}>Fecha</h5>
              <p className={styles.value}>{getDate(createdAt)}</p>
          </div>
        </div>
      </div>
    );
  }
  
  