import styles from './ModalInvestments.module.css';
import close from '../../../assets/icons/close.svg'
import warning from '../../../assets/icons/warning.svg'
import error from '../../../assets/icons/error.svg'
import success from '../../../assets/icons/success.svg'
import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';

export default function ModalInvestments({show, setShow, type, errors}){

  const {setFileErrors, setFileStatus} = useContext(AppContext);

  const handleClick = () => {
    setShow(false);
    setFileErrors([]);
    setFileStatus('');
  }

  return (
    show &&
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <img 
          src={close} 
          className={styles.close} 
          alt="icon"
          onClick={() => handleClick()}
        />
        <img
          src={type === 'success' ? success : type === 'warning' ? warning : error} 
          className={styles[type]} 
        />
        <h3>
          {type === 'success' 
            ? "¡Listado subido con éxito!"
            : type === 'warning' 
            ? "Listado subido parcialmente"
            : "Error al subir el listado"
          }
        </h3>
        <p>
          {type === 'success' 
            ? "La lista de inversiones fue subida exitosamente."
            : type === 'warning' 
            ? "La lista de inversiones fue subida parcialmente, se encontraron errores en las siguientes filas:"
            : "La lista de inversiones no pudo ser subida debido a los siguientes errores:"
          }
        </p>
        {errors?.length > 0 && 
          <ul>
            {errors?.map((error, i) => (
              <li key={i}>
                <i>{error}</i>
              </li>
            ))}
          </ul>
        }
        {(type === 'warning' || type === 'error') &&
          <p>Revisá los errores y volvé a intentarlo.</p>
        }
        <div className={styles.buttonContainer}>
          <p 
            className={styles.cancel}
            onClick={() => handleClick()}
          >
            Cerrar
          </p>
        </div>
      </div>
    </div>
  );
  }
  
  