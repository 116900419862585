

export const getDate = (datetime) => {
    var timezoneMinutes = new Date().getTimezoneOffset();
    let date = new Date(datetime);
    date = date.setMinutes(date.getMinutes() + timezoneMinutes);
    date = new Date(date);
    const day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    if(month < 10){
        month = `0${month}`
    }
    return `${day}/${month}/${year}`;
}
export const getDateFromAPI = (datetime) => {
    var timezoneMinutes = new Date().getTimezoneOffset();
    let date = new Date(datetime);
    date = date.setMinutes(date.getMinutes() + timezoneMinutes);
    date = new Date(date);
    const day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    if(month < 10){
        month = `0${month}`
    }
    return `${year}/${month}/${day}`;
}

export const getDateTime = (datetime) => {
    const date = new Date(datetime);
    const day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    if(month < 10){
        month = `0${month}`
    }
    let hours = date.getHours()-3;
    let minutes = date.getMinutes();
    if(hours < 10){
        hours = `0${hours}`
    }
    if(minutes < 10){
        minutes = `0${minutes}`
    }

    return `${day}/${month}/${year} - ${hours}:${minutes}`;
}