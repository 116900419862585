
export const dashboardUserTableHeadings = [
    {id:"instrument",label:"Instrumento"},
    {id:'capital', label:"Capital invertido"},
    {id:"currentRent",label:"Rendimiento acumulado"},
    // {id:"monthlyRentMoney",label: "Rendimiento mensual ($)"},
    {id:"monthlyRendPercentage",label:"Rendimiento mensual (%)"},
    {id:"existences",label:"Existencias"},
    {id:"investedMonths",label:"Meses invertidos"},
    {id:"startDate",label:"Fecha inicio"},
    {id:"endDate",label:"Fecha final"},
    {id:"status",label:"Estado"},
]
export const activityUserTableHeadings = [
    "Tipo de movimiento",
    "Monto",
    "Fecha",
]

export const userMovementsTestData = [
    {
        method: "cash",
        user:{
            firstName:"Joe",
            lastName:"Doe"
        }, 
        capital: "250.000", 
        currencyPerformance: "5.550",
        performancePercentage:"2,5",
        existing: "255.550", 
        dateStart: "12/01/2024", 
        dateEnd: "17/03/2024", 
    },
    {
        method: "crypto", 
        user:{
            firstName:"Joe",
            lastName:"Doe"
        }, 
        capital: "250.000", 
        currencyPerformance: "5.550",
        performancePercentage:"2,5",
        existing: "255.550", 
        dateStart: "12/01/2024", 
        dateEnd: "17/03/2024", 
    },
    {
        method: "Life Settlement", 
        user:{
            firstName:"Joe",
            lastName:"Doe"
        }, 
        capital: "250.000", 
        currencyPerformance: "5.550",
        performancePercentage:"2,5",
        existing: "255.550", 
        dateStart: "12/01/2024", 
        dateEnd: "17/03/2024", 
    },
    {
        method: "cash",
        user:{
            firstName:"Joe",
            lastName:"Doe"
        }, 
        capital: "250.000", 
        currencyPerformance: "5.550",
        performancePercentage:"2,5",
        existing: "255.550", 
        dateStart: "12/01/2024", 
        dateEnd: "17/03/2024", 
    },
    {
        method: "crypto", 
        user:{
            firstName:"Joe",
            lastName:"Doe"
        }, 
        capital: "250.000", 
        currencyPerformance: "5.550",
        performancePercentage:"2,5",
        existing: "255.550", 
        dateStart: "12/01/2024", 
        dateEnd: "17/03/2024", 
    },
    {
        method: "Life Settlement", 
        user:{
            firstName:"Joe",
            lastName:"Doe"
        }, 
        capital: "250.000", 
        currencyPerformance: "5.550",
        performancePercentage:"2,5",
        existing: "255.550", 
        dateStart: "12/01/2024", 
        dateEnd: "17/03/2024", 
    },
    {
        method: "cash",
        user:{
            firstName:"Joe",
            lastName:"Doe"
        }, 
        capital: "250.000", 
        currencyPerformance: "5.550",
        performancePercentage:"2,5",
        existing: "255.550", 
        dateStart: "12/01/2024", 
        dateEnd: "17/03/2024", 
    },
    {
        method: "crypto", 
        user:{
            firstName:"Joe",
            lastName:"Doe"
        }, 
        capital: "250.000", 
        currencyPerformance: "5.550",
        performancePercentage:"2,5",
        existing: "255.550", 
        dateStart: "12/01/2024", 
        dateEnd: "17/03/2024", 
    },
    {
        method: "Life Settlement", 
        user:{
            firstName:"Joe",
            lastName:"Doe"
        }, 
        capital: "250.000", 
        currencyPerformance: "5.550",
        performancePercentage:"2,5",
        existing: "255.550", 
        dateStart: "12/01/2024", 
        dateEnd: "17/03/2024", 
    },
]

export const adminMovementsTableHeadings = [
    {id:"user", label:"Usuario"},
    {id:"instrumento", label:"Instrumento"},
    {id:"capital", label:"Capital"},
    {id:"fee", label:"Fee"},
    {id:"annualRent", label:"Rendimiento anual"},
    {id:"monthlyRent", label:"Rendimiento mensual"},
    {id:"refund", label:"Devolución"},
    {id:"startDate", label:"Fecha de admisión"},
    {id:"endDate", label:"Fecha final"},
    {id:"status", label:"Estado"},
    {id:"actions", label:"Acciones"}
]