// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppTemplate_container__uI7pm{
    background-color: var(--white);
    display: flex;
    justify-content: end;
}
.AppTemplate_content__CYbcQ{
    min-height: 100vh;
    width: calc(100vw - 250px);
}

@media (max-width:576px) {
    .AppTemplate_container__uI7pm{
        flex-direction: column;
    }
    .AppTemplate_content__CYbcQ{
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/templates/AppTemplate/AppTemplate.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,aAAa;IACb,oBAAoB;AACxB;AACA;IACI,iBAAiB;IACjB,0BAA0B;AAC9B;;AAEA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".container{\n    background-color: var(--white);\n    display: flex;\n    justify-content: end;\n}\n.content{\n    min-height: 100vh;\n    width: calc(100vw - 250px);\n}\n\n@media (max-width:576px) {\n    .container{\n        flex-direction: column;\n    }\n    .content{\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AppTemplate_container__uI7pm`,
	"content": `AppTemplate_content__CYbcQ`
};
export default ___CSS_LOADER_EXPORT___;
