import { useContext, useEffect } from "react";
import { AppContext } from "../../contexts/AppContext";
import { useNavigate, useParams } from "react-router";
import { editAutomaticMovementForm, editManualMovementForm } from "../../data/forms";
import MovementsFormTemplate from "../../components/templates/admin/MovementsFormTemplate/MovementsFormTemplate";
import Loader from "../../components/atoms/Loader/Loader";
import AppTemplate from "../../components/templates/AppTemplate/AppTemplate";
import { adminAppRoutes } from "../../data/routes";


export default function EditMovement(){

  const {getMovement, editMovement, editMovementData, setEditMovementData, loading} = useContext(AppContext);
  const {id} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getMovement(id);
  },[id])


  return (
    loading ?
    <Loader/> :
    <main>
      <AppTemplate routes={adminAppRoutes}>
        <MovementsFormTemplate
          title="Editar inversión"
          formData={(editMovementData?.isAutomatic == 'true' ||editMovementData?.isAutomatic == true) ? editAutomaticMovementForm : editManualMovementForm}
          id={id}
          action={editMovement}
          apiData={editMovementData}
          setApiData={setEditMovementData}
          disabled={editMovementData?.isAutomatic == 'true' ? !editMovementData?.instrument || !editMovementData?.money || !editMovementData?.commissions || !editMovementData?.dateOfAdmission || !editMovementData?.returnDate || !editMovementData?.monthsInvested || !editMovementData?.annualRent || !editMovementData?.agreedPercentage || !editMovementData?.monthlyRent || !editMovementData?.percentageYield || !editMovementData?.momentaryPerformance || !editMovementData?.refundAmount :
          !editMovementData?.instrument || !editMovementData?.money || !editMovementData?.commissions || !editMovementData?.dateOfAdmission || !editMovementData?.returnDate || !editMovementData?.monthsInvested ||   !editMovementData?.momentaryPerformance
          }
        />
      </AppTemplate>
    </main>
  );
}
  