import styles from './Modal.module.css';
import close from '../../../assets/icons/close.svg'

export default function Modal({show, setShow, handleAction, deleteText}){

  return (
    show &&
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <img 
          src={close} 
          className={styles.close} 
          alt="icon"
          onClick={() => setShow(false)}
        />
        <h3>¿Estás seguro de que quieres borrar {deleteText}?</h3>
        <p>Esta acción no puede deshacerse.</p>
        <div className={styles.buttonContainer}>
          <p 
            className={styles.accept}
            onClick={handleAction}
          >
            Aceptar
          </p>
          <p 
            className={styles.cancel}
            onClick={() => setShow(false)}
          >
            Cancelar
          </p>
        </div>
      </div>
    </div>
  );
  }
  
  