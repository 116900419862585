import styles from './ModalSuccess.module.css';
import close from '../../../assets/icons/close.svg'
import { CONTACT_EMAIL, CONTACT_NUMBER } from '../../../data/variables';

export default function ModalSuccess({show, setShow}){

  return (
    show &&
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <img 
          src={close} 
          className={styles.close} 
          alt="icon"
          onClick={() => setShow(false)}
        />
        <h3>¡Solicitud de nueva inversión exitosa!</h3>
        <p>Tu solicitud fue enviada exitosamente. Nuestro equipo se contactará a la brevedad para comunicarte los pasos a seguir.<br/><br/>Ante cualquier consulta, podés comunicarte con nosotros vía email a <a href={`mailto:${CONTACT_EMAIL}`} target="_blank">{CONTACT_EMAIL}</a> o a nuestro Whatsapp <a href={`https://wa.me/${CONTACT_NUMBER}`} target="_blank">+{CONTACT_NUMBER}</a>.</p>
        <div className={styles.buttonContainer}>
          <p 
            className={styles.cancel}
            onClick={() => setShow(false)}
          >
            Cerrar
          </p>
        </div>
      </div>
    </div>
  );
  }
  
  