import { useContext, useState } from 'react';
import styles from './MovementsTemplate.module.css';
import { AppContext } from '../../../../contexts/AppContext';
import CardDocument from '../../../molecules/CardDocument/CardDocument';
import { getDate } from '../../../../utils/dateFormatter';
import Filter from '../../../organisms/Filter/Filter';
import filter from '../../../../assets/icons/filter.svg'
import Button from '../../../atoms/Button/Button';
import { userDocsFiltersData } from '../../../../data/filters';

export default function MovementsTemplate(){

  const {userFiles, getDocuments, userDocsFilters, setUserDocsFilters} = useContext(AppContext);
  const [showFilters, setShowFilters] = useState(false);
  

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h1>Documentos</h1>
        <Button
          icon={filter}
          style="naked"
          text="Filtrar"
          size="sm"
          handleClick={() => setShowFilters(true)}
        />
      </div>
      <div className={styles.contentContainer}>
        {userFiles?.data?.map((file,i) => (
          <CardDocument 
            key={i} 
            url={file.url} 
            label={
              file.type == "invoices" ? 
              `Invoice - ${getDate(file?.createdAt)}` :
              file.type == "report" ?
              `Reporte - ${getDate(file?.createdAt)}` :
              `Documento - ${getDate(file?.createdAt)}`
            }
          />
        ))}
      </div>
      <Filter
        show={showFilters}
        setShow={setShowFilters}
        getApiData={getDocuments}
        filters={userDocsFilters}
        setFilters={setUserDocsFilters}
        formFields={userDocsFiltersData?.formFields}
        button={userDocsFiltersData?.button}
      />
    </div>
  );
}
  
  