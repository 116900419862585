// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toast_container__wURg2{
    background-color: var(--error-color);
    position: fixed;
    top: 12px;
    right: 12px;
    border-radius: 8px;
    padding: 20px;
    z-index: 300000;
    width: 20%;
}
.Toast_content__1QiSk{
    color: white;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 4px;
    margin-top: 0px;
    text-align: left;
}
.Toast_content__1QiSk::first-letter{
    text-transform: uppercase;
}
.Toast_content__1QiSk:last-of-type{
    margin-bottom: 0px;
}

@media (max-width:576px){
    .Toast_container__wURg2{
        width:calc(95% - 48px);
        top:85px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/Toast/Toast.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,eAAe;IACf,SAAS;IACT,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,UAAU;AACd;AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,sBAAsB;QACtB,QAAQ;IACZ;AACJ","sourcesContent":[".container{\n    background-color: var(--error-color);\n    position: fixed;\n    top: 12px;\n    right: 12px;\n    border-radius: 8px;\n    padding: 20px;\n    z-index: 300000;\n    width: 20%;\n}\n.content{\n    color: white;\n    font-weight: 500;\n    font-size: 14px;\n    margin-bottom: 4px;\n    margin-top: 0px;\n    text-align: left;\n}\n.content::first-letter{\n    text-transform: uppercase;\n}\n.content:last-of-type{\n    margin-bottom: 0px;\n}\n\n@media (max-width:576px){\n    .container{\n        width:calc(95% - 48px);\n        top:85px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Toast_container__wURg2`,
	"content": `Toast_content__1QiSk`
};
export default ___CSS_LOADER_EXPORT___;
