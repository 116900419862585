import Form from '../../../organisms/Form/Form';
import styles from './SignInTemplate.module.css';
import {signInForm} from '../../../../data/forms';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';

export default function SignInTemplate(props){

  const {signIn, signInData, setSignInData} = props;

  const navigate = useNavigate();


  return (
    <div className={styles.container}>
      <Form 
        formFields={signInForm.formFields}
        button={{
          ...signInForm.button,
          handleClick: () => signIn(signInData, navigate)
        }}
        setApiData={setSignInData}
        apiData={signInData}
        signin
      />
    </div>
  );
}
  
  