import { useContext, useEffect } from "react";
import Loader from "../../components/atoms/Loader/Loader";
import AppTemplate from "../../components/templates/AppTemplate/AppTemplate";
import DashboardTemplate from "../../components/templates/users/DashboardTemplate/DashboardTemplate";
import { usersAppRoutes } from "../../data/routes";
import { AppContext } from "../../contexts/AppContext";


export default function Dashboard(){

  const {loading, getInstruments, getWalletData, getInvestments, getMe, filters, getWalletWithdrawals} = useContext(AppContext);

  useEffect(() => {
    getInstruments();
    getWalletData();
    getMe();
    getInvestments();
    getWalletWithdrawals();
  }, []);


  return (
    <main>
      {loading ?
        <Loader/> :
        <AppTemplate routes={usersAppRoutes}>
          <DashboardTemplate/>
        </AppTemplate>
      }
    </main>
  );
}
  