import AuthTemplate from "../components/templates/auth/AuthTemplate/AuthTemplate";
import OnboardingTemplate from "../components/templates/auth/OnboardingTemplate/OnboardingTemplate";

export default function Onboarding(){
    return (
      <main>
        <AuthTemplate onboarding>
          <OnboardingTemplate/>
        </AuthTemplate>
      </main>
    );
}
  