// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Form_container__sIFas{
    padding: 32px;
    border-radius: 12px;
    background-color: white;
    margin-bottom: 32px;
}
.Form_formContainer__Gvl6-{
    display: flex;
    flex-wrap: wrap;
}
.Form_linkContainer__fh4Ms{
    margin-bottom: 18px;
}
.Form_linkContainer__fh4Ms a,
.Form_linkContainer__fh4Ms p{
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    color: var(--secondary-color);
    transition: all 0.4s ease;
}
.Form_linkContainer__fh4Ms a:hover,
.Form_linkContainer__fh4Ms p:hover{
    color: var(--primary-color);
    transition: all 0.4s ease;
}


@media (max-width: 576px) {
    .Form_container__sIFas{
        padding: 20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/organisms/Form/Form.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,eAAe;AACnB;AACA;IACI,mBAAmB;AACvB;AACA;;IAEI,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,6BAA6B;IAC7B,yBAAyB;AAC7B;AACA;;IAEI,2BAA2B;IAC3B,yBAAyB;AAC7B;;;AAGA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["\n.container{\n    padding: 32px;\n    border-radius: 12px;\n    background-color: white;\n    margin-bottom: 32px;\n}\n.formContainer{\n    display: flex;\n    flex-wrap: wrap;\n}\n.linkContainer{\n    margin-bottom: 18px;\n}\n.linkContainer a,\n.linkContainer p{\n    text-decoration: none;\n    font-size: 14px;\n    font-weight: 500;\n    color: var(--secondary-color);\n    transition: all 0.4s ease;\n}\n.linkContainer a:hover,\n.linkContainer p:hover{\n    color: var(--primary-color);\n    transition: all 0.4s ease;\n}\n\n\n@media (max-width: 576px) {\n    .container{\n        padding: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Form_container__sIFas`,
	"formContainer": `Form_formContainer__Gvl6-`,
	"linkContainer": `Form_linkContainer__fh4Ms`
};
export default ___CSS_LOADER_EXPORT___;
