import { useContext } from "react";
import AuthTemplate from "../../components/templates/auth/AuthTemplate/AuthTemplate";
import RecoverPasswordTemplate from "../../components/templates/auth/RecoverPasswordTemplate/RecoverPasswordTemplate";
import { recoverPasswordEmailForm } from "../../data/forms";
import { AuthContext } from "../../contexts/AuthContext";


export default function RecoverPasswordEmail(){

  const {recoverPasswordVerifyEmail, recoverPasswordEmailData, setRecoverPasswordEmailData} = useContext(AuthContext);

  return (
    <main>
      <AuthTemplate>
        <RecoverPasswordTemplate
          action={recoverPasswordVerifyEmail}
          apiData={recoverPasswordEmailData}
          setApiData={setRecoverPasswordEmailData}
          formData={recoverPasswordEmailForm}
        />
      </AuthTemplate>
    </main>
  );
}