// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MaintenanceTemplate_container__BvHMc{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
}
.MaintenanceTemplate_heading__CcgGh{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MaintenanceTemplate_heading__CcgGh h1{
    font-size: 200px;
    text-align: center;
    font-weight: 600;
}
.MaintenanceTemplate_heading__CcgGh h3{
    font-size: 40px;
    font-weight: 600;
    margin-top: -12px;
    text-align: center;
}
.MaintenanceTemplate_container__BvHMc p{
    text-align: center;
    margin-top: 24px;
    font-size: 18px;
}

.MaintenanceTemplate_button__PsoMF{
    background-color: var(--primary-color);
    color: white;
    padding: 24px 36px;
    border-radius: 8px;
    margin-top: 42px;
    width: -moz-fit-content;
    width: fit-content;
    cursor:pointer;
    transition: all 0.4s ease;
}
.MaintenanceTemplate_button__PsoMF:hover{
    transform: translateY(-3px);
    transition: all 0.4s ease;
}

.MaintenanceTemplate_imgContainer__kUpDf{
    margin-bottom: 24px;
    width: 200px;
    display: flex;
    justify-content: center;
}
.MaintenanceTemplate_imgContainer__kUpDf img{
    filter: var(--primary-filter);
}`, "",{"version":3,"sources":["webpack://./src/components/templates/MaintenanceTemplate/MaintenanceTemplate.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,sCAAsC;IACtC,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,uBAAkB;IAAlB,kBAAkB;IAClB,cAAc;IACd,yBAAyB;AAC7B;AACA;IACI,2BAA2B;IAC3B,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,6BAA6B;AACjC","sourcesContent":[".container{\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    background-color: var(--white);\n}\n.heading{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.heading h1{\n    font-size: 200px;\n    text-align: center;\n    font-weight: 600;\n}\n.heading h3{\n    font-size: 40px;\n    font-weight: 600;\n    margin-top: -12px;\n    text-align: center;\n}\n.container p{\n    text-align: center;\n    margin-top: 24px;\n    font-size: 18px;\n}\n\n.button{\n    background-color: var(--primary-color);\n    color: white;\n    padding: 24px 36px;\n    border-radius: 8px;\n    margin-top: 42px;\n    width: fit-content;\n    cursor:pointer;\n    transition: all 0.4s ease;\n}\n.button:hover{\n    transform: translateY(-3px);\n    transition: all 0.4s ease;\n}\n\n.imgContainer{\n    margin-bottom: 24px;\n    width: 200px;\n    display: flex;\n    justify-content: center;\n}\n.imgContainer img{\n    filter: var(--primary-filter);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MaintenanceTemplate_container__BvHMc`,
	"heading": `MaintenanceTemplate_heading__CcgGh`,
	"button": `MaintenanceTemplate_button__PsoMF`,
	"imgContainer": `MaintenanceTemplate_imgContainer__kUpDf`
};
export default ___CSS_LOADER_EXPORT___;
