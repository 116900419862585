import { useContext, useEffect } from "react";
import Loader from "../../../components/atoms/Loader/Loader";
import AppTemplate from "../../../components/templates/AppTemplate/AppTemplate";
import UserFormTemplate from "../../../components/templates/admin/UserFormTemplate/UserFormTemplate";
import { adminAppRoutes } from "../../../data/routes";
import { AppContext } from "../../../contexts/AppContext";
import { useNavigate, useParams } from "react-router";
import { editUserForm } from "../../../data/forms";


export default function EditUser(){

  const {getUser, user, editUser, editUserData, setEditUserData, loading, uploadUserDocument, generateUserReport} = useContext(AppContext);
  const {id} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getUser(id);
  },[id])

  const handleUploadFile = (e) => {
    uploadUserDocument(
      {
        userId: id, 
        file:e.target.files[0],
      }, 
      navigate
    );
  }

  return (
    loading ?
    <Loader/> :
    <main>
      <AppTemplate routes={adminAppRoutes}>
        <UserFormTemplate 
          title="Editar usuario"
          formData={editUserForm}
          userId={id}
          action={editUser}
          apiData={editUserData}
          setApiData={setEditUserData}
          buttonAction={handleUploadFile}
          generateReport={() => generateUserReport(+id, navigate)}
        />
      </AppTemplate>
    </main>
  );
}
  