// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GraphicDashboard_container__rDwOh{
    width: 80%;
    padding: 60px 24px 0px 24px;
    display: flex;
    flex-direction: column;
}
.GraphicDashboard_container__rDwOh h3{
    margin-top: 70px;
    font-weight: 600;
}
.GraphicDashboard_graphContainer__4Kr1C{
    margin-top: 24px;
}

@media (max-width:576px) {
    .GraphicDashboard_container__rDwOh{
        width: 100%;
        padding: 32px 0px 24px 0px;
    }
    .GraphicDashboard_container__rDwOh h3{
        margin-top: 00px;
    }
    .GraphicDashboard_graphContainer__4Kr1C{
        margin-top: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/GraphicDashboard/GraphicDashboard.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,WAAW;QACX,0BAA0B;IAC9B;IACA;QACI,gBAAgB;IACpB;IACA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".container{\n    width: 80%;\n    padding: 60px 24px 0px 24px;\n    display: flex;\n    flex-direction: column;\n}\n.container h3{\n    margin-top: 70px;\n    font-weight: 600;\n}\n.graphContainer{\n    margin-top: 24px;\n}\n\n@media (max-width:576px) {\n    .container{\n        width: 100%;\n        padding: 32px 0px 24px 0px;\n    }\n    .container h3{\n        margin-top: 00px;\n    }\n    .graphContainer{\n        margin-top: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `GraphicDashboard_container__rDwOh`,
	"graphContainer": `GraphicDashboard_graphContainer__4Kr1C`
};
export default ___CSS_LOADER_EXPORT___;
