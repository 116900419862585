import { useContext, useEffect } from "react";
import Loader from "../../components/atoms/Loader/Loader";
import AppTemplate from "../../components/templates/AppTemplate/AppTemplate";
import MovementsTemplate from "../../components/templates/admin/MovementsTemplate/MovementsTemplate";
import { adminAppRoutes } from "../../data/routes";
import { AppContext } from "../../contexts/AppContext";
import { useNavigate } from "react-router";


export default function CashFlow(){

  const {getMovements, getLastVersions, movements, uploadReport, movementsType, loading, key, calculatePerformance, getLastCalculationDate, lastVersions, automaticUpdateStatus} = useContext(AppContext);

  useEffect(() => {
    getMovements(movementsType);
  },[key])

  useEffect(() => {
    getLastVersions();
  },[])

  useEffect(() => {
    automaticUpdateStatus == '' && getLastCalculationDate();
  },[automaticUpdateStatus])


  const navigate = useNavigate();
  const handleUploadFile = (e) => {
    uploadReport({file:e.target.files[0]}, navigate);
  }
  
  return (
    <main>
      {loading ?
        <Loader/> :
        <AppTemplate routes={adminAppRoutes}>
          <MovementsTemplate 
            apiData={movements?.data}
            buttonAction={handleUploadFile}
            calculatePerformance={() => calculatePerformance(navigate)}
            lastVersions={lastVersions?.data}
          />
        </AppTemplate>
      }
    </main>
  );
}
  