import { useContext, useEffect } from "react";
import { AppContext } from "../../contexts/AppContext";
import { useNavigate, useParams } from "react-router";
import { addManualMovementForm, addAutomaticMovementForm } from "../../data/forms";
import MovementsFormTemplate from "../../components/templates/admin/MovementsFormTemplate/MovementsFormTemplate";
import Loader from "../../components/atoms/Loader/Loader";
import AppTemplate from "../../components/templates/AppTemplate/AppTemplate";
import { adminAppRoutes } from "../../data/routes";


export default function AddMovement(){

  const {addMovement, addMovementData, setAddMovementData, loading, getUsers, users} = useContext(AppContext);

  const {id} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getUsers();
  },[])

  
  return (
    loading ?
    <Loader/> :
    <main>
      <AppTemplate routes={adminAppRoutes}>
        <MovementsFormTemplate
          title="Agregar inversión"
          formData={addMovementData?.isAutomatic == 'true' ? addAutomaticMovementForm : addManualMovementForm}
          id={id}
          action={addMovement}
          apiData={addMovementData}
          setApiData={setAddMovementData}
          users={users?.data}
          disabled={addMovementData?.isAutomatic == 'true' ? !addMovementData?.userId || !addMovementData?.instrument || !addMovementData?.money || !addMovementData?.commissions || !addMovementData?.dateOfAdmission || !addMovementData?.returnDate || !addMovementData?.monthsInvested || !addMovementData?.annualRent || !addMovementData?.agreedPercentage || !addMovementData?.monthlyRent || !addMovementData?.percentageYield || !addMovementData?.momentaryPerformance || !addMovementData?.refundAmount :
          !addMovementData?.instrument || !addMovementData?.money || !addMovementData?.commissions || !addMovementData?.dateOfAdmission || !addMovementData?.returnDate || !addMovementData?.monthsInvested ||   !addMovementData?.momentaryPerformance
          }
        />
      </AppTemplate>
    </main>
  );
}
  