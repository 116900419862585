import { useContext, useEffect } from "react";
import Loader from "../../components/atoms/Loader/Loader";
import AppTemplate from "../../components/templates/AppTemplate/AppTemplate";
import MovementsTemplate from "../../components/templates/users/MovementsTemplate/MovementsTemplate";
import { usersAppRoutes } from "../../data/routes";
import { AppContext } from "../../contexts/AppContext";


export default function Documents(){

  const {loading, getDocuments} = useContext(AppContext);

  useEffect(() => {
    getDocuments();
  },[])

  return (
    <main>
      {loading ? 
        <Loader/> :
        <AppTemplate routes={usersAppRoutes}>
          <MovementsTemplate/>
        </AppTemplate>
      }
    </main>
  );
}
  