
import styles from './UserFormTemplate.module.css';
import back from '../../../../assets/icons/back.svg';
import Form from '../../../organisms/Form/Form';
import { useNavigate } from 'react-router';
import Toast from '../../../atoms/Toast/Toast';
import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import Button from '../../../atoms/Button/Button';

export default function UserFormTemplate(props){

  const navigate = useNavigate();
  const {title, action, setApiData, apiData, formData, userId, buttonAction, generateReport} = props;
  const {toast, setToast, fileStatus, generateReportStatus} = useContext(AppContext);

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.headingContainer}>
          <div 
            className={styles.backButton}
            onClick={() => navigate('/admin/app/users')}
          >
            <img 
              src={back} 
              className={styles.back}
              alt="back"
            />
            <p>Atrás</p>
          </div>
          <h1>{title}</h1>
        </div>
        {userId && 
          <div className={styles.buttonContainer}>
            <Button 
              type="file" 
              accept=".pdf"
              text={
                fileStatus == "loading" ? 
                "Subiendo invoice..." : 
                fileStatus == "success" ?
                "Invoice agregado con éxito!" :
                "Agregar invoice" }
              style={
                fileStatus == "loading" ? 
                "disabled" : 
                fileStatus == "success" ?
                "success" : 
                "primary" 
              }
              size="md"
              handleChange={
                (fileStatus == "loading" || fileStatus == "success") ? 
                undefined : 
                buttonAction
              }
            />
            <Button 
              text={
                generateReportStatus == "loading" ? 
                "Generando reporte..." : 
                generateReportStatus == "success" ?
                "Reporte generado con éxito!" :
                "Generar reporte" }
              style={
                generateReportStatus == "loading" ? 
                "disabled" : 
                generateReportStatus == "success" ?
                "success" : 
                "primary" 
              }
              size="md"
              handleClick={
                (fileStatus == "loading" || fileStatus == "success") ? 
                undefined : 
                generateReport
              }
            />
          </div>
        }
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <Form 
            formFields={formData.formFields}
            button={{
              ...formData.button,
              handleClick: () => action(apiData, navigate)
            }}
            setApiData={setApiData}
            apiData={apiData}
          />
        </div>
      </div>
      <Toast
        toast={toast}
        setToast={setToast}
        content={toast?.content}
      />
    </div>
  );
}
  
  