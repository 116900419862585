import { useContext, useEffect, useState } from 'react';
import styles from './ActivityTemplate.module.css';
import { AppContext } from '../../../../contexts/AppContext';
import CardActivity from '../../../molecules/CardActivity/CardActivity';
import { activityUserTableHeadings } from '../../../../data/tables';
import Table from '../../../organisms/Table/Table';
import ModalSuccess from '../../../molecules/ModalSuccess/ModalSuccess';
import Button from '../../../atoms/Button/Button';
import money from '../../../../assets/icons/dollar.svg';

export default function ActivityTemplate(){

  const {userActivity, sendDepositRequest, depositRequestStatus} = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    depositRequestStatus == 'success' && setShowModal(true);
  },[depositRequestStatus])

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h1>Actividad</h1>
        <Button 
          text={
            depositRequestStatus == "loading" ? 
            "Enviando solicitud..." : 
            depositRequestStatus == "success" ?
            "Solicitud enviada con éxito!" :
            "Solicitar nueva inversión" }
          style={
            depositRequestStatus == "loading" ? 
            "disabled" : 
            depositRequestStatus == "success" ?
            "success" : 
            "primary" 
          }
          size="md"
          icon={money}
          handleClick={() => sendDepositRequest()}
        />
      </div>
      <div className={styles.contentContainer}>
        <Table
          Card={CardActivity}
          columns={activityUserTableHeadings?.length}
          tableHeadings={activityUserTableHeadings}
          tableContent={userActivity?.data}
        />
      </div>
      <ModalSuccess
        show={showModal} 
        setShow={setShowModal}
      />
    </div>
  );
}
  
  