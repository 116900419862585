import styles from './AuthTemplate.module.css';
import logo from '../../../../assets/logos/horizontal-logo-white.svg'
import Toast from '../../../atoms/Toast/Toast';
import Loader from '../../../atoms/Loader/Loader';
import { useContext } from 'react';
import { AuthContext } from '../../../../contexts/AuthContext';
import ContactButton from '../../../atoms/ContactButton/ContactButton';
import mail from '../../../../assets/icons/mail.svg';
import { CONTACT_EMAIL } from '../../../../data/variables';

export default function AuthTemplate({onboarding, title, children}){

  const {toast, setToast, loading} = useContext(AuthContext);

  return (
    <div className={styles.container}>
      {loading && <Loader/>}
      <div className={styles.content}>
        <div className={styles[onboarding ? "titleOnboarding" : "logoAuth"]}>
          <img src={logo} className={styles.logo} alt="icon"/>
        </div>
        {children}
        <div className={styles[onboarding ? "logoOnboarding" : "logoAuth"]}>
          <p className={styles.subtitle}>Powered by:</p>
          <h1 className={styles.title}>Bruber LLC</h1>
        </div>
      </div>
      <Toast
        toast={toast}
        setToast={setToast}
        content={toast.content}
      />
      <ContactButton
        img={mail}
        link={`mailto:${CONTACT_EMAIL}`}
      />
    </div>
  );
}
  
  