// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFoundTemplate_container__JzLxw{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
}
.NotFoundTemplate_heading__AkRmh h1{
    font-size: 200px;
    text-align: center;
    font-weight: 600;
}
.NotFoundTemplate_heading__AkRmh h3{
    font-size: 30px;
    margin-top: -12px;
    text-align: center;
}
.NotFoundTemplate_container__JzLxw p{
    text-align: center;
    margin-top: 32px;
    font-size: 18px;
}

.NotFoundTemplate_button__guyVb{
    background-color: var(--primary-color);
    color: white;
    padding: 24px 36px;
    border-radius: 8px;
    margin-top: 32px;
    width: -moz-fit-content;
    width: fit-content;
    cursor:pointer;
    transition: all 0.4s ease;
}
.NotFoundTemplate_button__guyVb:hover{
    transform: translateY(-3px);
    transition: all 0.4s ease;
}`, "",{"version":3,"sources":["webpack://./src/components/templates/NotFoundTemplate/NotFoundTemplate.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,sCAAsC;IACtC,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,uBAAkB;IAAlB,kBAAkB;IAClB,cAAc;IACd,yBAAyB;AAC7B;AACA;IACI,2BAA2B;IAC3B,yBAAyB;AAC7B","sourcesContent":[".container{\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    background-color: var(--white);\n}\n.heading h1{\n    font-size: 200px;\n    text-align: center;\n    font-weight: 600;\n}\n.heading h3{\n    font-size: 30px;\n    margin-top: -12px;\n    text-align: center;\n}\n.container p{\n    text-align: center;\n    margin-top: 32px;\n    font-size: 18px;\n}\n\n.button{\n    background-color: var(--primary-color);\n    color: white;\n    padding: 24px 36px;\n    border-radius: 8px;\n    margin-top: 32px;\n    width: fit-content;\n    cursor:pointer;\n    transition: all 0.4s ease;\n}\n.button:hover{\n    transform: translateY(-3px);\n    transition: all 0.4s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NotFoundTemplate_container__JzLxw`,
	"heading": `NotFoundTemplate_heading__AkRmh`,
	"button": `NotFoundTemplate_button__guyVb`
};
export default ___CSS_LOADER_EXPORT___;
