
import Table from '../../../organisms/Table/Table';
import styles from './DashboardTemplate.module.css';
import {dashboardUserTableHeadings, userMovementsTestData} from '../../../../data/tables';
import { dashboardTestData } from '../../../../data/example';
import CardDashboard from '../../../molecules/CardDashboard/CardDashboard';
import GraphicDashboard from '../../../molecules/GraphicDashboard/GraphicDashboard';
import { useNavigate } from 'react-router';
import CardMovement from '../../../molecules/CardMovement/CardMovement';
import { useContext, useState } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import capital from '../../../../assets/bk/capital.svg';
import existencias from '../../../../assets/bk/existencias.svg';
import rendimiento from '../../../../assets/bk/rendimiento.svg';
import Button from '../../../atoms/Button/Button';
import filter from '../../../../assets/icons/filter.svg';
import Filter from '../../../organisms/Filter/Filter';
import { userDashboardFilters } from '../../../../data/filters';
import Paginator from '../../../atoms/Paginator/Paginator';


export default function DashboardTemplate({pagination}){

  const navigate = useNavigate();
  const {me, userWallet, userInvestments, getInvestments, userInvestmentsFilters, setUserInvestmentsFilters, userWalletWithdrawal} = useContext(AppContext);

  const [showFilters, setShowFilters] = useState(false);


  return (
    <div className={styles.container}>
        <section className={styles.summary}>
            <div className={styles.mainContent}>
              <div className={styles.heading}>
                  <h2>Hola {me?.data?.firstName || "Usuario"}!</h2>
                  <h1>Dashboard</h1>
              </div>
              <div className={styles.cardsContainer}>
                <CardDashboard 
                  bkIcon={capital}
                  title= "Capital"
                  subtitle={userWallet?.data?.invested}
                  color="primary"
                />
                <CardDashboard 
                  bkIcon={existencias}
                  title= "Rendimiento"
                  subtitle={
                    (userWallet?.data?.currencyPerformance == "0" || !userWallet?.data?.currencyPerformance) ?
                    "-" : `${userWallet?.data?.currencyPerformance}`
                  }
                  // percentage={
                  //   userWallet?.data?.percentagePerformance == "0" ?
                  //   null : userWallet?.data?.percentagePerformance
                  // }
                  color="secondary"
                />
                <CardDashboard 
                  bkIcon={rendimiento}
                  title= "Existencias"
                  subtitle={
                    (userWalletWithdrawal?.existence && userWalletWithdrawal?.existence > 0) ?
                    (userWallet?.data?.existence - userWalletWithdrawal?.existence) :
                    userWallet?.data?.existence
                  }
                  color="tertiary"
                />
              </div>
            </div>
            <div className={styles.graphicContainer}>
              <GraphicDashboard
                graphicData={dashboardTestData.graphic}
                title="Diversificación"
              />
            </div>
        </section>
        <section className={styles.lastMovements}>
          <div className={styles.lastMovementsHeading}>
            <h3>Inversiones</h3>
            <Button
              icon={filter}
              style="naked"
              text="Filtrar"
              size="sm"
              handleClick={() => setShowFilters(true)}
            />
          </div>
          <div className={styles.tableContainer}>
            <Table
              Card={CardMovement}
              tableHeadings={dashboardUserTableHeadings}
              tableContent={userInvestments?.data}
            />
            <div className={styles.paginationContainer}>
              <Paginator
                {...userInvestments?.meta}
                action={getInvestments}
              />
            </div>
          </div>
        </section>
        <Filter
          show={showFilters}
          setShow={setShowFilters}
          getApiData={getInvestments}
          filters={userInvestmentsFilters}
          setFilters={setUserInvestmentsFilters}
          formFields={userDashboardFilters?.formFields}
          button={userDashboardFilters?.button}
        />
    </div>
  );
}
  
  