// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactButton_container__1dG-r{
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 75px;
    height: 75px;
    background-color: var(--primary-color);
    border-radius: 50%;
    z-index: 80000000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    cursor: pointer;
}

.ContactButton_container__1dG-r img{
    width: 40px;
    filter: var(--white-filter);
}

.ContactButton_container__1dG-r:hover{
    filter: brightness(1.1);
    transition: all 0.3s ease;
}

@media (max-width: 576px) {
    .ContactButton_container__1dG-r{
        width: 65px;
        height: 65px;
        bottom: 15px;
        right: 15px;
    }
    .ContactButton_container__1dG-r img{
        width: 30px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/ContactButton/ContactButton.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,WAAW;IACX,YAAY;IACZ,sCAAsC;IACtC,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,2BAA2B;AAC/B;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI;QACI,WAAW;QACX,YAAY;QACZ,YAAY;QACZ,WAAW;IACf;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".container{\n    position: fixed;\n    bottom: 30px;\n    right: 30px;\n    width: 75px;\n    height: 75px;\n    background-color: var(--primary-color);\n    border-radius: 50%;\n    z-index: 80000000;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    transition: all 0.3s ease;\n    cursor: pointer;\n}\n\n.container img{\n    width: 40px;\n    filter: var(--white-filter);\n}\n\n.container:hover{\n    filter: brightness(1.1);\n    transition: all 0.3s ease;\n}\n\n@media (max-width: 576px) {\n    .container{\n        width: 65px;\n        height: 65px;\n        bottom: 15px;\n        right: 15px;\n    }\n    .container img{\n        width: 30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContactButton_container__1dG-r`
};
export default ___CSS_LOADER_EXPORT___;
