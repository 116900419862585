
import styles from './ContactButton.module.css';

export default function ContactButton({link, download = false, img, onClick}){

    return (
        <a 
            href={link}
            target="_blank"
            className={styles.container}
            download={download}
            onClick={onClick}
        >
            <img src={img} alt="logo"/>
        </a>
    );
}
  