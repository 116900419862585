import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './globalStyles.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { appRoutes, onboardingRoutes } from './data/routes';
import Onboarding from './pages/Onboarding';
import { AuthProvider } from './contexts/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import NotFound from './pages/NotFound';
import { initAxiosInterceptor } from './utils/interceptor';
import { AppProvider } from './contexts/AppContext';


initAxiosInterceptor();

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route exact path="/" element={<Onboarding/>}/>
      {onboardingRoutes?.map((route, i) => (
        <Route 
          key={i}
          path={route.path}
          element={route.component}
          exact
        />
      ))}
      {appRoutes?.map((route, i) => (
          <Route 
            key={i}
            path={route.path}
            element={route.component}
            exact
          />
        ))}
      <Route
        path="*"
        element={<NotFound />}
      />
    </>
))

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthProvider>
      <AppProvider>
        <RouterProvider router={router} />
      </AppProvider>
    </AuthProvider>
  </React.StrictMode>
);
